// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setUserActive, setUserIdle } from '../../actions';
import { getInputDebounceMs } from '../../constants';
import { isUserCurrentlyActive } from '../../selectors/vifAuthoring';

export class DebouncedInput extends React.Component {
  constructor(props) {
    super(props);

    // Radiobuttons and checkboxes uses checked instead of value
    this.usingChecked = props.type === 'checkbox' || props.type === 'radio';

    if (this.usingChecked && _.isUndefined(props.checked)) {
      throw new Error(`DebouncedInput with type="${props.type}" needs "checked" property to be set.`);
    }

    if (!this.usingChecked && _.isUndefined(props.value)) {
      throw new Error(`DebouncedInput with type="${props.type}" needs "value" property to be set.`);
    }

    this.state = {
      value: _.isNil(props.value) ? '' : props.value,
      checked: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.timeoutId = null;
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {  // eslint-disable-line camelcase
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value
      });
    }
  }

  handleChange(event) {
    event.persist();

    if (this.usingChecked) {
      this.setState({ checked: event.target.checked });
    } else {
      this.setState({ value: event.target.value });
    }

    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }

    if (!isUserCurrentlyActive(this.props.vifAuthoring)) {
      this.props.onSetUserActive();
    }

    this.timeoutId = setTimeout(() => {
      this.props.onSetUserIdle();
      return this.props.onChange(event);
    }, getInputDebounceMs());
  }

  handleKeyDown(event) {
    // fix EN-16941
    if (this.props.forceEnterKeyHandleChange && event.key === 'Enter') {
      event.preventDefault();
      this.handleChange(event);
    }
  }

  render() {
    const props = _.omit(
      this.props,
      [
        'forceEnterKeyHandleChange',
        'onSetUserActive',
        'onSetUserIdle',
        'vifAuthoring'
      ]
    );

    if (this.usingChecked) {
      return <input {...props} checked={this.state.checked} onChange={this.handleChange} />;
    } else {
      return <input {...props} value={this.state.value} onChange={this.handleChange} onKeyDown={this.handleKeyDown} />;
    }
  }
}

DebouncedInput.defaultProps = {
  type: 'text'
};

DebouncedInput.propTypes = {
  checked: PropTypes.bool,
  formatLabel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onSetUserActive: PropTypes.func,
  onSetUserIdle: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.any
};

const mapDispatchToProps = {
  onSetUserActive: setUserActive,
  onSetUserIdle: setUserIdle
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(DebouncedInput);
