// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  appendSeries,
  removeSeries,
  setMeasureAggregation,
  setMeasureColumn,
  setUseSecondaryAxisForLines
} from '../../actions';
import {
  getCurrentMetadata,
  getValidFlyoutMeasures,
  getValidMeasures,
  hasData
} from '../../selectors/metadata';
import {
  getComboChartLineSeries,
  getCurrentSeriesIndex,
  getPointAggregation,
  getSeries,
  isBarChart,
  isColumnChart,
  isComboChart,
  isFeatureMap,
  isNewGLMap,
  isTimelineChart
} from '../../selectors/vifAuthoring';
import ColumnAndAggregationSelector from './ColumnAndAggregationSelector';
import FlyoutFactory from 'common/components/legacy/Flyout';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import { getIconClassForDataType } from 'common/views/dataTypeMetadata';

// Constants
import {
  AGGREGATION_TYPES,
  MAXIMUM_COMBO_CHART_MEASURES,
  MAXIMUM_MEASURES,
  SERIES_VARIANT_LINE
} from '../../constants';

export class MeasureSelector extends Component {
  componentDidUpdate() {
    if (this.selector) {
      new FlyoutFactory(this.selector);
    }
  }

  handleOnAddColumnAndAggregation = (columnName) => {
    const {
      isFlyoutSeries,
      metadata,
      onAppendSeries,
      onSetMeasureColumn,
      onSetUseSecondaryAxisForLines,
      seriesVariant,
      vifAuthoring
    } = this.props;

    if (isNewGLMap(vifAuthoring)) {
      const currentSeriesIndex = getCurrentSeriesIndex(vifAuthoring);
      onSetMeasureColumn({
        columnName,
        isFlyoutSeries,
        relativeIndex: currentSeriesIndex
      });
      return;
    }

    // If we are adding a line series and there aren't any lines yet,
    // set lines to use the secondary axis.
    if ((seriesVariant === SERIES_VARIANT_LINE) && _.isEmpty(getComboChartLineSeries(vifAuthoring))) {
      onSetUseSecondaryAxisForLines(true);
    }
    const rowDisplayUnit = _.get(metadata, 'data.metadata.rowLabel', null);
    const measureAggregationFunction = this.getDefaultAggregationFunctionForColumnName(columnName);

    onAppendSeries({
      isFlyoutSeries,
      isInitialLoad: false,
      measureAggregationFunction,
      measureColumnName: columnName,
      rowDisplayUnit,
      seriesVariant
    });
  }

  handleOnDeleteColumnAndAggregation = (measureIndex) => {
    const {
      isFlyoutSeries,
      onRemoveSeries,
      onSetMeasureColumn,
      onSetUseSecondaryAxisForLines,
      seriesVariant,
      vifAuthoring
    } = this.props;
    const currentSeriesIndex = isNewGLMap(vifAuthoring) ? getCurrentSeriesIndex(vifAuthoring) : measureIndex;

    if (isNewGLMap(vifAuthoring)) {
      onSetMeasureColumn({
        columnName: null,
        isFlyoutSeries,
        relativeIndex: currentSeriesIndex
      });
      return;
    }
    // If we are deleting the last line series, set lines to use the
    // primary axis in order to not draw the empty secondary axis.
    if ((seriesVariant === SERIES_VARIANT_LINE) && (getComboChartLineSeries(vifAuthoring).length === 1)) {
      onSetUseSecondaryAxisForLines(false);
    }

    const deleteFirstMeasureIsAllowed = isBarChart(vifAuthoring) || isColumnChart(vifAuthoring) ||
                                        isComboChart(vifAuthoring) || isTimelineChart(vifAuthoring);

    if (!isFlyoutSeries && measureIndex === 0 && !deleteFirstMeasureIsAllowed) {
      onSetMeasureColumn({
        columnName: null,
        isFlyoutSeries,
        relativeIndex: currentSeriesIndex
      });
      return;
    }

    onRemoveSeries({
      isFlyoutSeries,
      relativeIndex: currentSeriesIndex
    });
  }

  getDefaultAggregationFunctionForColumnName = (columnName) => {
    if (_.isNil(columnName)) {
      return 'count';
    }
    const renderTypeName = this.getColumnRenderTypeName(columnName);
    return (renderTypeName === 'number') ? 'sum' : null;
  }

  getColumnRenderTypeName = (columnName) => {
    const columns = _.get(this.props.metadata, 'data.columns', []);
    const foundColumn = _.find(columns, (column) => (column.fieldName === columnName));
    return _.get(foundColumn, 'renderTypeName');
  }

  handleOnUpdateColumnAndAggregation = (index, columnAndAggregationValue) => {
    const {
      isFlyoutSeries,
      onSetMeasureAggregation,
      onSetMeasureColumn,
      vifAuthoring
    } = this.props;

    const {
      aggregation,
      column,
      seriesIndex
    } = columnAndAggregationValue;

    const currentSeriesIndex = isNewGLMap(vifAuthoring) ? getCurrentSeriesIndex(vifAuthoring) : seriesIndex;
    const relativeIndex = isFlyoutSeries ? index : currentSeriesIndex;

    onSetMeasureColumn({
      columnName: column,
      isFlyoutSeries,
      relativeIndex
    });

    let aggregationFunction = aggregation;

    if (_.isNil(column)) {
      // If column is nil, aggregation must be count
      aggregationFunction = 'count';
    } else {
      // If column is not nil
      const renderTypeName = this.getColumnRenderTypeName(column);

      if (renderTypeName !== 'number') {
        // If column is not a number, aggregation must be null
        aggregationFunction = null;
      } else if (aggregationFunction === 'count') {
        // If column is a number, aggregation must not be count
        aggregationFunction = 'sum';
      }
    }

    onSetMeasureAggregation({
      aggregationFunction,
      isFlyoutSeries,
      relativeIndex
    });
  }

  shouldRenderMeasureForNewGLMap() {
    const { vifAuthoring } = this.props;
    const isNewGLMapEnabled = FeatureFlags.value('enable_new_maps');

    return isNewGLMapEnabled &&
      isNewGLMap(vifAuthoring) &&
      getPointAggregation(vifAuthoring) !== 'region_map';
  }

  renderMeasureSelectors() {
    const {
      isFlyoutSeries,
      metadata,
      series,
      showAlreadySelectedColumns,
      shouldRenderAddMeasureLink,
      vifAuthoring
    } = this.props;

    const validMeasures = isFlyoutSeries ?
      getValidFlyoutMeasures(metadata) :
      getValidMeasures(metadata);

    const options = [
      {
        title: I18n.translate('shared.visualizations.panes.data.fields.measure.no_value'),
        value: null
      },
      ...validMeasures.map((validMeasure) => ({
        title: validMeasure.name,
        value: validMeasure.fieldName,
        type: validMeasure.renderTypeName,
        render: this.renderMeasureOption
      }))
    ];

    let columnAndAggregationValues;
    let shouldRenderDeleteLink = false;
    const maximumMeasures = isComboChart(vifAuthoring) ?
      MAXIMUM_COMBO_CHART_MEASURES :
      MAXIMUM_MEASURES;
    const shouldRenderAddLink = (series.length < maximumMeasures) && shouldRenderAddMeasureLink;

    if (isNewGLMap(vifAuthoring)) {
      const measureIndex = getCurrentSeriesIndex(vifAuthoring);
      const seriesItem = getSeries(vifAuthoring)[measureIndex];

      columnAndAggregationValues = [{
        aggregation: _.get(seriesItem, 'dataSource.measure.aggregationFunction'),
        column: _.get(seriesItem, 'dataSource.measure.columnName'),
        seriesIndex: measureIndex
      }];

    } else {

      if ((isBarChart(vifAuthoring) || isColumnChart(vifAuthoring) ||
          isComboChart(vifAuthoring) || isTimelineChart(vifAuthoring)) && series.length > 1)
          shouldRenderDeleteLink = true;

      columnAndAggregationValues = _.map(series, (seriesItem) => {
        const aggregationFunction = _.get(seriesItem, 'dataSource.measure.aggregationFunction');
        const columnName = _.get(seriesItem, 'dataSource.measure.columnName');
        const displayAggregation = this.isNumericColumn(validMeasures, columnName);

        return {
          aggregation: _.isNil(columnName) ? AGGREGATION_TYPES[0].type : aggregationFunction,
          column: columnName,
          displayAggregation,
          seriesIndex: seriesItem.seriesIndex
        };
      });
    }

    const disabled = options.length <= 1 ||
      isFeatureMap(vifAuthoring) ||
      this.shouldRenderMeasureForNewGLMap();
    const attributes = {
      aggregations: AGGREGATION_TYPES,
      columns: options,
      disableInProgressColumnSelector: disabled,
      isFlyoutSeries,
      onDelete: this.handleOnDeleteColumnAndAggregation,
      onAdd: this.handleOnAddColumnAndAggregation,
      onUpdate: this.handleOnUpdateColumnAndAggregation,
      showAlreadySelectedColumns,
      showNewInProgressColumnAndAggregateSelector: false,
      shouldRenderAddLink,
      shouldRenderDeleteLink,
      values: columnAndAggregationValues
    };

    return (
      <ColumnAndAggregationSelector {...attributes} />
    );
  }

  isNumericColumn(measures, column) {
    const measure = _.find(measures, (measureItem) => (measureItem.fieldName === column));
    return _.isNil(measure) ?
      false :
      (measure.renderTypeName === 'number');
  }

  renderMeasureOption(option) {
    const iconClassForDataType = getIconClassForDataType(option.type);

    return (
      <div className="dataset-column-selector-option">
        <span className={iconClassForDataType}></span> {option.title}
      </div>
    );
  }

  render() {
    const shouldRender = hasData(this.props.metadata);

    return shouldRender ? this.renderMeasureSelectors() : null;
  }
}

MeasureSelector.defaultProps = {
  aggregationTypes: AGGREGATION_TYPES,
  showAlreadySelectedColumns: false
};

MeasureSelector.propTypes = {
  isFlyoutSeries: PropTypes.bool,
  listItemKeyPrefix: PropTypes.string,
  metadata: PropTypes.object,
  onAppendSeries: PropTypes.func,
  onRemoveSeries: PropTypes.func,
  onSetMeasureAggregation: PropTypes.func,
  onSetMeasureColumn: PropTypes.func,
  onSetUseSecondaryAxisForLines: PropTypes.func,
  series: PropTypes.arrayOf(PropTypes.object),
  seriesVariant: PropTypes.string,
  shouldRenderAddMeasureLink: PropTypes.bool,
  showAlreadySelectedColumns: PropTypes.bool,
  shouldRenderDeleteMeasureLink: PropTypes.bool,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onAppendSeries: appendSeries,
  onRemoveSeries: removeSeries,
  onSetMeasureAggregation: setMeasureAggregation,
  onSetMeasureColumn: setMeasureColumn,
  onSetUseSecondaryAxisForLines: setUseSecondaryAxisForLines
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(MeasureSelector);
