import React from 'react';
import I18n from 'common/i18n';
import classNames from 'classnames';
import { ForgeCheckbox, ForgeTooltip } from '@tylertech/forge-react';

// components
import Checkbox from 'common/components/Checkbox';
import Dropdown from 'common/components/Dropdown';
import TableColumnTooltip from '../../TableColumnTooltip';
import Button, { VARIANTS } from 'common/components/Button';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import { getIconClassForDataType } from 'common/views/dataTypeMetadata';
import FeatureFlags from 'common/feature_flags';
import {
  HIERARCHY_AGGREGATION_TYPES,
  NON_NUMERICAL_HIERARCHY_AGGREGATION_TYPES,
  MAX_HIERARCHY_GROUPINGS
} from 'common/authoring_workflow/constants';
import { DROPDOWN_CATEGORIES } from 'common/components/Dropdown/constants';

// types
import { ViewColumn } from 'common/types/viewColumn';
import { SoQLType } from 'common/types/soql';
import { Aggregation } from 'common/authoring_workflow/reducers/types';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { HierarchyColumnConfig } from 'common/visualizations/vif';

// project constants
const scope = 'shared.visualizations.panes.data';

export const GROUPABLE_DATA_TYPES = new Set([
  SoQLType.SoQLNumberT,
  SoQLType.SoQLTextT,
  SoQLType.SoQLBooleanT,
  SoQLType.SoQLBooleanTAltName,
  SoQLType.SoQLFixedTimestampT,
  SoQLType.SoQLFixedTimestampAltT,
  SoQLType.SoQLFloatingTimestampT,
  SoQLType.SoQLFloatingTimestampAltT,
  SoQLType.SoQLURLT,
  SoQLType.SoQLJsonT
]);

export interface TableHierarchyGroupingColumnProps {
  aggregations: ColumnAggregation[];
  columnMetadata: ViewColumn;
  columnIndex: number;
  columnConfig?: HierarchyColumnConfig;
  showGrandTotalChecked?: boolean;
  existingIsGroupingHierarchiesLength: number;
  hierarchyIndex: number;
  hierarchyContainsGrouping: boolean;
  handleOnUpdateHierarchy: (currentColumnConfig: HierarchyColumnConfig) => void;
  handleOnAggregationSelection: (aggregationValue: string, columnName: string) => void;
}

const TableHierarchyGroupingColumn = (props: TableHierarchyGroupingColumnProps) => {
  const {
    hierarchyContainsGrouping,
    columnMetadata,
    columnIndex,
    columnConfig,
    existingIsGroupingHierarchiesLength,
    hierarchyIndex,
    showGrandTotalChecked,
    handleOnUpdateHierarchy,
    handleOnAggregationSelection
  } = props;

  // if this column is not a grouping column, but it is part of the table hierarchies, than we will use the aggregation function from the vif
  const columnAggregationValue = (!columnConfig?.isGrouping && columnConfig?.aggregation) || null;

  const columnDataTypeCannotBeGrouped = !GROUPABLE_DATA_TYPES.has(columnMetadata.dataTypeName);
  const maxHierarchyGroupingsReached =
    existingIsGroupingHierarchiesLength >= MAX_HIERARCHY_GROUPINGS && !columnConfig?.isGrouping;
  const disableGroupingCheckbox =
    // The checkbox should never be disabled if the column is being grouped. This way you can always uncheck it.
    !columnConfig?.isGrouping &&
    (columnDataTypeCannotBeGrouped || maxHierarchyGroupingsReached || columnConfig?.hidden);

  const handleGroupingsOnClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const nextConfig = {
      columnName: columnMetadata.fieldName ?? '',
      aggregation: null,
      isGrouping: event.currentTarget?.checked
    };
    handleOnUpdateHierarchy(nextConfig);
  };

  const checkboxAttributes = {
    id: `column-${hierarchyIndex}-${columnIndex}-checkbox`,
    disabled: disableGroupingCheckbox,
    checked: columnConfig?.isGrouping ?? false,
    onClick: handleGroupingsOnClick,
    'data-testid': `table-column-grouping-checkbox-${columnMetadata?.fieldName}`
  };
  // if column type is not numerical, limit aggregation options
  let columnAggregationOptions;
  if (columnMetadata.dataTypeName == SoQLType.SoQLNumberT) {
    columnAggregationOptions = HIERARCHY_AGGREGATION_TYPES.map(({ title, type: value }) => ({
      title,
      value
    }));
  } else {
    columnAggregationOptions = NON_NUMERICAL_HIERARCHY_AGGREGATION_TYPES.map(({ title, type: value }) => ({
      title,
      value
    }));
  }

  // if the column supports some non-standard aggregations, add them to the aggregation dropdown
  columnAggregationOptions.push(
    ...props.aggregations.map(({ aggregationType }) => ({
      title: aggregationType[0].toUpperCase() + aggregationType.slice(1).toLowerCase(),
      value: aggregationType
    }))
  );

  const isTotalChecked = showGrandTotalChecked ?? false;
  let disabled: boolean | undefined = false;
  if (!isTotalChecked) {
    disabled = columnConfig?.isGrouping || !hierarchyContainsGrouping || columnConfig?.hidden;
  } else {
    disabled = columnConfig?.hidden;
  }
  disabled = disabled || false;

  const aggregationAttributes = {
    id: `aggregation-selection-${hierarchyIndex}-${columnIndex}`,
    disabled,
    label: I18n.t('fields.measure.aggregation_label', { scope, number: columnIndex + 1 }),
    options: columnAggregationOptions,
    value: columnAggregationValue,
    dropdownCategory: DROPDOWN_CATEGORIES.AGGREGATION,
    onSelection: (aggregation: Aggregation) =>
      handleOnAggregationSelection(aggregation.value, columnMetadata.fieldName)
  };

  const renderVisibilityToggle = () => {
    const currentlyHidden = columnConfig?.hidden;
    return (
      <Button
        variant={VARIANTS.TRANSPARENT}
        onClick={() => {
          const currentAggregation = columnConfig?.aggregation || null;
          handleOnUpdateHierarchy({
            columnName: columnMetadata.fieldName as string,
            aggregation: currentAggregation,
            isGrouping: false,
            hidden: !currentlyHidden
          });
        }}
        className="table-hierarchy-column-visibility-toggle-btn"
      >
        <SocrataIcon name={currentlyHidden ? IconName.EyeBlocked : IconName.Eye} />
      </Button>
    );
  };

  const renderCheckbox = () => {
    return (
      <ForgeCheckbox>
        {columnDataTypeCannotBeGrouped && (
          <ForgeTooltip position="bottom">
            {I18n.t('fields.table_hierarchies.grouping_unavailable', { scope })}
          </ForgeTooltip>
        )}
        <input type="checkbox" {...checkboxAttributes} readOnly />
      </ForgeCheckbox>
    );
  };

  return (
    <div
      className={classNames('table-hierarchy-column', { 'hidden-column': columnConfig?.hidden })}
      data-testid={`hierarchy-${hierarchyIndex}-table-hierarchy-column-${columnMetadata.fieldName}`}
      key={columnIndex}
    >
      {!columnConfig?.isGrouping && renderVisibilityToggle()}
      {renderCheckbox()}
      <TableColumnTooltip
        id={`hierarchy-${hierarchyIndex}-column-${columnIndex}`}
        content={columnMetadata.name}
      >
        <div className="table-hierarchy-title">
          <span className={getIconClassForDataType(columnMetadata.dataTypeName)}></span>
          <span>{columnMetadata.name}</span>
        </div>
      </TableColumnTooltip>
      {/* AGGREGATION SELECTOR DROPDOWN */}
      <div className="aggregation-dropdown-container">
        <Dropdown {...aggregationAttributes} />
      </div>
    </div>
  );
};

export default TableHierarchyGroupingColumn;
