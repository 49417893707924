// Vendor Imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

export class SwapControls extends Component {
  renderIcon = ({ name, iconClassName, onClick }) => {
    const { className } = this.props;
    const swapButtonClasses = classNames(className, `${iconClassName}-button`);

    return (
      <button type="button" className={swapButtonClasses} onClick={onClick}>
        <span className={name}></span>
      </button>
    );
  }

  render() {
    const { index, onClick, showUpIcon, showDownIcon } = this.props;
    const arrowUpIconAttributes = {
      name: 'icon-arrow-up2',
      iconClassName: 'up-icon',
      onClick: () => onClick(index, index - 1)
    };
    const arrowDownIconAttributes = {
      name: 'icon-arrow-down2',
      iconClassName: 'down-icon',
      onClick: () => onClick(index, index + 1)
    };

    return (
      <div className="swap-controls">
        {showUpIcon && this.renderIcon(arrowUpIconAttributes)}
        {showDownIcon && this.renderIcon(arrowDownIconAttributes)}
      </div>
    );
  }
}

SwapControls.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  showUpIcon: PropTypes.bool,
  showDownIcon: PropTypes.bool,
};

export default SwapControls;
