// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import AxisPrecisionSelector from '../panes/AxisPane/AxisPrecisionSelector';

// Constants
import { PRECISION_AUTOMATIC, PRECISION_CUSTOM } from '../../constants';

export class PrecisionSelector extends Component {
  onRadioButtonChange = (event) => {
    const { onSetPrecision, precision, defaultPrecision } = this.props;
    const value = _.get(event, 'target.value');

    if (value === PRECISION_AUTOMATIC) {
      onSetPrecision();
    } else if ((value === PRECISION_CUSTOM) && !_.isFinite(precision)) {
      onSetPrecision(defaultPrecision);
    }
  }

  render() {
    const { onSetPrecision, precision, id, className, customPrecisionDisabled } = this.props;
    const selectorAttributes = {
      disabled: false,
      className,
      id,
      onRadioButtonChange: this.onRadioButtonChange,
      onSliderChange: onSetPrecision,
      precision,
      customPrecisionDisabled
    };

    return (
      <AxisPrecisionSelector {...selectorAttributes} />
    );
  }
}

PrecisionSelector.propTypes = {
  precision: PropTypes.number,
  defaultPrecision: PropTypes.number,
  customPrecisionDisabled: PropTypes.bool,
  onSetPrecision: PropTypes.func
};

PrecisionSelector.defaultProps = {
  defaultPrecision: 0
};

export default PrecisionSelector;
