import _ from 'lodash';
import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { assert, assertIsOneOfTypes } from 'common/assertions';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';

// @ts-expect-error
import reducer from './reducers/index';
// @ts-expect-error
import getVifTemplates from './vifs/index';
// @ts-expect-error
import { setFilters, setShowLegend, setColorPaletteProperties } from './actions';
// @ts-expect-error
import { load } from './vifs/loader';
// @ts-expect-error
import { defaultState as defaultMetadata } from './reducers/metadataCollection';
// @ts-expect-error
import AuthoringWorkflow from './components/AuthoringWorkflow';

import { Vif } from 'common/visualizations/vif';
import { Filters } from 'common/components/SingleSourceFilterBar/types';

interface AuthoringWorkflowProps {
  vif: Partial<Vif>;
  filters: Filters;
  useLogger?: boolean;
  enableFiltering: boolean;
  backButtonText: Text;
  onBack: () => void;
  onComplete: () => void;
  onCancel: () => void;
}

class AuthoringWorkflowIndex extends React.Component<AuthoringWorkflowProps> {
  store: ReturnType<typeof createStore>;

  constructor(props: AuthoringWorkflowProps) {
    super(props);
    const logger = createLogger();

    let { vif } = this.props;
    const { filters } = this.props;

    const formatVersion = _.get(vif, 'format.version');
    const formatType = _.get(vif, 'format.type');

    assertIsOneOfTypes(formatVersion, 'number');
    assert(
      formatType === 'visualization_interchange_format',
      'formatType must be "visualization_interchange_format"'
    );

    vif = this.props.vif ? migrateVif(this.props.vif) : {};
    // Adding initial VIF to vifs list
    // Serves as a fallback for reset options
    const vifs = _.merge(getVifTemplates(), { initialVif: vif });
    const vifType = _.get(vif, 'series[0].type', null);
    const initialState = {
      metadataCollection: defaultMetadata,
      vifAuthoring: {
        vifs: vifs,
        authoring: {
          filters,
          selectedVisualizationType: vifType,
          showCenteringAndZoomingSaveMessage: false
        }
      }
    };

    const middleware: any[] = [thunk];
    if (this.props.useLogger) {
      middleware.push(logger);
    }

    const composeEnhancers =
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Uncomment the following line to get tracing in Redux Dev Tools
          //trace: true,
          name: 'AuthoringWorkflow'
        })) ||
      compose;

    this.store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

    load(this.store.dispatch, vif);
    this.store.dispatch(setFilters(filters));
    this.store.dispatch(setShowLegend(_.get(vif, 'series[0].showLegend', true)));
    this.store.dispatch(setColorPaletteProperties());
  }

  render() {
    return (
      <Provider store={this.store}>
        <AuthoringWorkflow {...this.props} />
      </Provider>
    );
  }
}

export default AuthoringWorkflowIndex;
