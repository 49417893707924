import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { formatDate } from 'common/dates';
import I18n from 'common/i18n';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import InfoPaneComponent from 'common/components/InfoPaneComponent';
import InfoPaneButtons from './InfoPaneButtons';
import { FOOTER } from 'common/components/InfoPane/constants';
import { isInternalToPublicFederatedSoQLBasedView } from 'common/views/view_types';
import { getVisualizationCanvasName } from '../selectors';

export function mapStateToProps(state) {
  const {
    autolinkedDescription,
    isEphemeral,
    lastUpdatedAt,
    parentView,
    view,
    vifs
  } = state;
  const { hideDataSourceLink } = _.get(window, 'serverConfig.customConfigurations', {});

  const updatedAtString = isEphemeral ?
    I18n.t('visualization_canvas.info_pane.unsaved') :
    formatDate(lastUpdatedAt);
  const series = _.get(vifs[0], 'series', []);

  let dataSourceDetails = {
    location: FOOTER,
    primaryDataSource: {
      name: parentView.name,
      url: `/d/${parentView.id}`
    },
    otherDataSources: []
  };

  if (series.length > 1) {
    _.each(series, (seriesItem) => {
      const domain = _.get(seriesItem, 'dataSource.domain') || getDefaultDomain();
      const datasetUid = _.get(seriesItem, 'dataSource.datasetUid');
      const isPrimaryLayer = _.get(seriesItem, 'primary');
      const url = `https://${domain}/d/${datasetUid}`;
      const isNewGLMap = _.get(seriesItem, 'type') === 'map';
      const alreadyHasDatasetUid = _.includes(_.map(dataSourceDetails, 'otherDataSources'), datasetUid) || datasetUid === parentView.id;

      // Only unified maps have multiple layers from different datasets.
      // All other chart types will be sourced by a single dataset.
      if (!isNewGLMap || isPrimaryLayer || alreadyHasDatasetUid) {
        return;
      }

      dataSourceDetails.otherDataSources.push({ url, domain, datasetUid });
    });
  }

  if (hideDataSourceLink === 'true' || isInternalToPublicFederatedSoQLBasedView(parentView)) {
    dataSourceDetails = {};
  }

  return {
    name: getVisualizationCanvasName(state),
    description: autolinkedDescription,
    provenance: _.get(view, 'provenance', null),
    category: (view || parentView).category,
    isPaneCollapsible: true,
    dataSourceDetails,
    metadata: {
      first: {
        label: I18n.t('visualization_canvas.info_pane.updated'),
        content: updatedAtString
      },
      second: {
        label: I18n.t('visualization_canvas.info_pane.view_count'),
        content: _.get(view, 'viewCount', 0)
      }
    },
    renderButtons() {
      return <InfoPaneButtons vifs={vifs} />;
    }
  };
}

export default connect(mapStateToProps)(InfoPaneComponent);
