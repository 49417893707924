import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setTableRowStripeStyleAction } from '../../../actions.js';
import AgColorSelector from './AgGrid/AgColorSelector';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { isEqual } from 'lodash';
import { AuthoringWorkflowState } from 'common/authoring_workflow/reducers/types';
import { RowStripeStyle } from 'common/types/agGrid/rowStripe.js';
import { Configuration } from 'common/visualizations/vif.js';
import { COLORS } from 'common/authoring_workflow/constants';

interface RowStripeSelectorProps {
  configuration: Configuration;
  setTableRowStripeStyle: (tableRowStripeStyle: RowStripeStyle) => void;
}

const RowStripeSelector: FC<RowStripeSelectorProps> = ({ configuration, setTableRowStripeStyle }) => {
  const defaultRowStripeValue = {
    base: {
      text: '#000',
      fill: '#fff'
    },
    alternate: {
      text: '#000',
      fill: '#fff'
    }
  };

  const initializeDefaultRowStripeValue = () => {
    if (configuration?.rowStripeStyle?.base && configuration?.rowStripeStyle?.alternate)
      return configuration?.rowStripeStyle;
    return defaultRowStripeValue;
  };

  const [currentRowStripeValue, setCurrentRowStripeValue] = useState<RowStripeStyle>(
    initializeDefaultRowStripeValue()
  );

  const isDefaultStyle = () => {
    return isEqual(currentRowStripeValue, defaultRowStripeValue);
  };

  const setRowStripeValue = (category: string, key: string, newColor: string) => {
    setCurrentRowStripeValue((prevRowStripeValue) => ({
      ...prevRowStripeValue,
      [category]: {
        ...prevRowStripeValue[category],
        [key]: newColor
      }
    }));
  };

  useEffect(() => {
    setTableRowStripeStyle(currentRowStripeValue);
  }, [currentRowStripeValue]);

  const resetRowStripeValue = () => {
    setCurrentRowStripeValue(defaultRowStripeValue);
    setTableRowStripeStyle(defaultRowStripeValue);
  };
  const resetButtonStyle = {
    color: '#3f51b5',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer'
  };

  return (
    <>
      <div
        style={{
          marginTop: '1rem',
          marginBottom: '1rem'
        }}
      >
        <span>{I18n.t('shared.visualizations.panes.presentation.fields.row_stripe.header_base')}</span>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '4rem 4rem',
            gap: '1rem'
          }}
        >
          <div className={'authoring-field'}>
            <AgColorSelector
              color={currentRowStripeValue.base?.text || ''}
              onChangeColor={(newColor) => setRowStripeValue('base', 'text', newColor)}
              label={I18n.t('shared.visualizations.panes.presentation.fields.row_stripe.text_label')}
              palette={COLORS}
            />
          </div>
          <div className={'authoring-field'}>
            <AgColorSelector
              color={currentRowStripeValue.base?.fill || ''}
              onChangeColor={(newColor) => setRowStripeValue('base', 'fill', newColor)}
              label={I18n.t('shared.visualizations.panes.presentation.fields.row_stripe.fill_label')}
              palette={COLORS}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: '1rem',
          marginBottom: '1rem'
        }}
      >
        <span>{I18n.t('shared.visualizations.panes.presentation.fields.row_stripe.header_alternate')}</span>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '4rem 4rem',
            gap: '1rem'
          }}
        >
          <div className={'authoring-field'}>
            <AgColorSelector
              color={currentRowStripeValue.alternate?.text || ''}
              onChangeColor={(newColor) => setRowStripeValue('alternate', 'text', newColor)}
              label={I18n.t('shared.visualizations.panes.presentation.fields.row_stripe.text_label')}
              palette={COLORS}
            />
          </div>
          <div className={'authoring-field'}>
            <AgColorSelector
              color={currentRowStripeValue.alternate?.fill || ''}
              onChangeColor={(newColor) => setRowStripeValue('alternate', 'fill', newColor)}
              label={I18n.t('shared.visualizations.panes.presentation.fields.row_stripe.fill_label')}
              palette={COLORS}
            />
          </div>
        </div>
      </div>
      <div className={'authoring-field'} style={resetButtonStyle} onClick={() => resetRowStripeValue()}>
        <ForgeButton className="ag-grid-reset-format-button">
          <button
            aria-label={I18n.t('shared.visualizations.panes.presentation.fields.row_stripe.reset_text')}
            disabled={isDefaultStyle()}
          >
            <ForgeIcon name="refresh" />
            <span>{I18n.t('shared.visualizations.panes.presentation.fields.row_stripe.reset_text')}</span>
          </button>
        </ForgeButton>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  setTableRowStripeStyle: setTableRowStripeStyleAction
};

const mapStateToProps = (state: AuthoringWorkflowState) => ({
  configuration: state.vifAuthoring.vifs.initialVif.configuration
});

export default connect(mapStateToProps, mapDispatchToProps)(RowStripeSelector);
