// Vendor Imports
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  getCurrentMetadata,
  getDatasetLink,
  getDatasetName,
  hasData
} from '../selectors/metadata';
import I18n from 'common/i18n';
import { VisualizationRenderer } from 'common/visualizations';

export class TableView extends React.Component {
  componentDidMount() {
    if (hasData(this.props.metadata)) {
      this.renderTableView();
    }
  }

  componentDidUpdate() {
    if (hasData(this.props.metadata)) {
      this.renderTableView();
    }
  }

  renderBasedOn = () => {
    const { metadata } = this.props;

    return (
      <span className="authoring-based-on">
        <a href={getDatasetLink(metadata)} target="_blank" rel="noreferrer">
          {getDatasetName(metadata)}
        </a>
      </span>
    );
  }

  renderTableView = () => {
    const { vif } = this.props;
    if (this.tableView.querySelector('.socrata-visualization')) {
      this.tableRenderer.update(vif);
    } else {
      this.tableRenderer = new VisualizationRenderer(vif, this.tableView);
    }
  }

  render() {
    const basedOn = hasData(this.props.metadata) ? this.renderBasedOn() : null;

    return (
      <div className="authoring-table-view-container">
        <h6 className="authoring-table-view-title">
          {I18n.t('shared.visualizations.table_view.title')}{basedOn}
        </h6>
        <div className="authoring-table-view" ref={(ref) => this.tableView = ref} />
      </div>
    );
  }
}

TableView.propTypes = {
  metadata: PropTypes.object,
  vif: PropTypes.object
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vif: state.vifAuthoring.vifs.dataTable
});

export default connect(mapStateToProps)(TableView);
