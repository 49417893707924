// Vendor Imports
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import I18n from 'common/i18n';
import DatePicker from 'common/components/DatePicker';
import DebouncedTextArea from '../../shared/DebouncedTextArea';
import { RELATIVE_FILTERS, RELATIVE_FILTER_VALUES, DATE_FORMAT } from 'common/dates';

const scope = 'shared.visualizations.panes.legends_and_flyouts';

export class AnnotationsSelector extends Component {
  state = {
    showNewInProgressAnnotationSelector: false
  };

  handleOnClickAddLink = () => {
    this.setState({ showNewInProgressAnnotationSelector: true });

    this.props.onAdd({ date: null, description: null });
  }

  onChangeDate = (date, annotation, index) => {
    const { annotations, onUpdate, onSetShowLegend } = this.props;
    let newDate;
    if (date === RELATIVE_FILTERS.TODAY) {
      newDate = moment().startOf('day').format(DATE_FORMAT);
    } else if (date === RELATIVE_FILTERS.YESTERDAY) {
      newDate = moment().startOf('day').subtract(1, 'days').format(DATE_FORMAT);
    } else {
      newDate = date;
    }
    const newAnnotation = _.merge({}, annotation, { date: newDate });
    const annotaionsCount = _.chain(annotations).map('date').without(null).size().value();
    this.setState({ showNewInProgressAnnotationSelector: false });

    if (annotaionsCount === 0) {
      onSetShowLegend(true);
    }

    onUpdate(index, newAnnotation);
  }

  onChangeDescription = (description, annotation, index) => {
    const newAnnotation = _.merge({}, annotation, { description });

    this.props.onUpdate(index, newAnnotation);
  }

  renderShowAnnotationLegendOpenedCheckbox = () => {
    const { showAnnotationsInLegend, onSetShowAnnotationsInLegend } = this.props;
    const inputProps = {
      checked: showAnnotationsInLegend,
      id: 'display-annotations-in-legend',
      onChange: (event) => onSetShowAnnotationsInLegend(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field checkbox show-annotation-selector">
        <input {...inputProps} />
        <label className="inline-label" htmlFor="display-annotations-in-legend">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('fields.annotations.display_annotations_in_legend', { scope })}
        </label>
      </div>
    );
  }

  renderAddLink = () => {
    const linkAttributes = {
      id: 'add-annotation-link',
      className: this.state.showNewInProgressAnnotationSelector ? 'disabled' : null,
      onClick: () => this.handleOnClickAddLink()
    };

    return (
      <div className="add-annotation-link-container">
        <a {...linkAttributes}>
          <span className="socrata-icon-add" />
          {I18n.t('fields.annotations.add_annotation_value', { scope })}
        </a>
      </div>
    );
  }

  renderDeleteLink = (index) => {
    const attributes = {
      className: 'delete-annotation-link',
      onClick: () => this.props.onRemove(index)
    };

    return (
      <div className="annotation-delete-link-container">
        <a {...attributes}>
          <span className="socrata-icon-close" />
        </a>
      </div>
    );
  }

  renderAnnotationLabel = (index) => {
    const annotationCount = index + 1;
    return (
      <div className="annotation-list-item">
        <div className="annotation-count">{annotationCount}</div>
        <div className="annotation-label">Annotation
          <span> {annotationCount}</span>
        </div>
        {this.renderDeleteLink(index)}
      </div>
    );
  }

  renderDateField = (annotation, index) => {
    const datePickerProps = {
      date: _.isNil(annotation.date) ? undefined : annotation.date,
      onChangeDate: (date) => this.onChangeDate(date, annotation, index),
      showTodayButton: true,
      showYesterdayButton: true,
    };

    return (
      <div className="authoring-field">
        <DatePicker {...datePickerProps} />
      </div>
    );
  }

  renderDescriptionField = (annotation, index) => {
    const id = `annotation-description-${index}`;
    const authoringFieldKey = `${annotation.date}-${index}`;
    const textAreaAttributes = {
      className: 'annotation-text-input text-area',
      id,
      onChange: (event) => this.onChangeDescription(event.target.value, annotation, index),
      value: _.isNil(annotation.description) ? '' : annotation.description
    };

    return (
      <div className="authoring-field" key={authoringFieldKey}>
        <label className="block-label" htmlFor={id}>
          {I18n.t('fields.annotations.description.title', { scope })}
        </label>
        <DebouncedTextArea {...textAreaAttributes} />
      </div>
    );
  }

  renderAnnotationSelector = (annotation, index) => {
    return (
      <li className="annotation-list" key={index}>
        {this.renderAnnotationLabel(index)}
        {this.renderDateField(annotation, index)}
        {this.renderDescriptionField(annotation, index)}
      </li>
    );
  }

  render() {
    const { annotations } = this.props;
    const annotationSelectors = _.map(annotations, this.renderAnnotationSelector);

    return (
      <div className="annotation-container">
        <ul className="annotaion-list-container">
          {annotationSelectors}
        </ul>
        {this.renderAddLink()}
        {this.renderShowAnnotationLegendOpenedCheckbox()}
      </div>
    );
  }
}

AnnotationsSelector.defaultProps = {
  annotations: [],
  showAnnotationsInLegend: false
};

AnnotationsSelector.propTypes = {
  annotations: PropTypes.array,
  showAnnotationsInLegend: PropTypes.bool,
  onAdd: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onSetShowAnnotationsInLegend: PropTypes.func
};

export default AnnotationsSelector;
