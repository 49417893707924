import React, { Component } from 'react';
import classNames from 'classnames';
import { ForgeIcon } from '@tylertech/forge-react';
import FeatureFlags from 'common/feature_flags';

interface ICustomizationTabProps {
  id: string;
  title: string;
  icon: string;
  selected: boolean;
  onTabNavigation: (tabId: string) => void;
}

class CustomizationTab extends Component<ICustomizationTabProps> {
  public static defaultProps = {
    selected: false
  };

  linkAttributes = () => {
    const { id, selected, onTabNavigation, title } = this.props;

    return {
      id: `${id}-link`,
      href: `#${id}`,
      onClick: (event: React.MouseEvent) => {
        event.preventDefault();
        onTabNavigation(id);
      },
      role: 'tab',
      'aria-label': title,
      'aria-controls': `${id}-panel`,
      'aria-selected': selected
    };
  };

  listItemAttributes = () => {
    return {
      key: this.props.id,
      className: classNames('tab-link', { current: this.props.selected })
    };
  };

  render() {
    const enableForgeTabs = FeatureFlags.valueOrDefault('enable_forge_authoring_tabs', false);
    const { id, title, icon } = this.props;

    return (
      <li {...this.listItemAttributes()}>
        <a {...this.linkAttributes()}>
          {enableForgeTabs ? (
            <ForgeIcon slot="leading" name={icon} />
          ) : (
            <span className={`icon-${icon}`} role="presentation" />
          )}
        </a>
        <span id={id} className="pane-tooltip" aria-hidden={true}>
          {title}
        </span>
      </li>
    );
  }
}

export default CustomizationTab;
