// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  addAnnotation,
  appendSeries,
  removeAnnotation,
  removeSeries,
  setShowLegend,
  setMeasureColumn,
  setRowInspectorTitleColumnName,
  setShowAnnotationsInLegend,
  setShowSlicePercentsInFlyouts,
  updateAnnotation
} from '../../../actions';
import {
  getCurrentMetadata,
  getDisplayableColumns,
  isDimensionTypeCalendarDate,
  hasData
} from '../../../selectors/metadata';
import {
  getAnnotations,
  getColorByColumn,
  getCurrentDimensionColumnName,
  getRowInspectorTitleColumnName,
  getSeries,
  getShowAnnotationsInLegend,
  getShowSlicePercentsInFlyouts,
  hasReferenceLineLabels,
  isBarChart,
  isCalendar,
  isColumnChart,
  isComboChart,
  isFeatureMap,
  isGrouping,
  isGroupingOrHasMultipleNonFlyoutSeries,
  isHistogram,
  isPieChart,
  isRegionMap,
  isScatterChart,
  isTimelineChart
} from '../../../selectors/vifAuthoring';
import MultiColumnSelector from '../../shared/MultiColumnSelector';
import FlyoutUnitsSelector from '../../shared/FlyoutUnitsSelector';
import LegendsSelector from './LegendsSelector';
import MeasureSelector from '../../shared/MeasureSelector';
import AnnotationsSelector from './AnnotationsSelector';
import EmptyPane from '../EmptyPane';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import SingleColumnSelector from '../../shared/SingleColumnSelector';
import I18n from 'common/i18n';
import { getIconClassForDataType } from 'common/views/dataTypeMetadata';

// Constants
import { SERIES_TYPE_FLYOUT } from '../../../constants';

const scope = 'shared.visualizations.panes.legends_and_flyouts';

export class LegendsAndFlyoutsPane extends Component {
  renderFlyoutUnits = () => {
    return (
      <AccordionPane
        key="units"
        title={I18n.t('subheaders.flyout_units.title', { scope })}>
        <p className="authoring-field-description units-description">
          <small>{I18n.t('subheaders.flyout_units.description', { scope })}</small>
        </p>
        <FlyoutUnitsSelector metadata={this.props.metadata} />
      </AccordionPane>
     );
  }

  renderFlyoutDetails = () => {
    const { vifAuthoring } = this.props;
    const shouldRender = !isGrouping(vifAuthoring);

    if (!shouldRender) {
      return null;
    }
    const series = getSeries(vifAuthoring).map((item, index) => {
      return _.extend({ seriesIndex: index }, item);
    });
    const flyoutSeries = _.filter(series, (item) => {
      return item.type === SERIES_TYPE_FLYOUT;
    });
    const selectorAttributes = {
      isFlyoutSeries: true,
      listItemKeyPrefix: 'LegendsAndFlyoutsPane',
      series: flyoutSeries,
      shouldRenderAddMeasureLink: true,
      shouldRenderDeleteMeasureLink: true
    };
    const showSlicePercentCheckbox = isPieChart(vifAuthoring) ?
      this.renderShowSlicePercents() :
      null;

    return (
      <AccordionPane
        key="details"
        title={I18n.t('subheaders.flyout_details.title', { scope })}>
        <MeasureSelector {...selectorAttributes} />
        {showSlicePercentCheckbox}
      </AccordionPane>
    );
  }

  renderAnnotations = () => {
    const {
      onAddAnnotation,
      onRemoveAnnotation,
      onSetShowLegend,
      onUpdateAnnotation,
      onSetShowAnnotationsInLegend,
      vifAuthoring,
      metadata
    } = this.props;

    const annotations = getAnnotations(vifAuthoring);
    const showAnnotationsInLegend = getShowAnnotationsInLegend(vifAuthoring);
    const currentDimensionColumnName = getCurrentDimensionColumnName(vifAuthoring);
    const isCalendarDateType = isDimensionTypeCalendarDate(metadata, currentDimensionColumnName);
    const selectorAttributes = {
      annotations,
      showAnnotationsInLegend,
      onAdd: onAddAnnotation,
      onUpdate: onUpdateAnnotation,
      onRemove: onRemoveAnnotation,
      onSetShowLegend,
      onSetShowAnnotationsInLegend
    };

    return isCalendarDateType && (
      <AccordionPane
        key="annotations"
        title={I18n.t('subheaders.annotations.title', { scope })}>
        <AnnotationsSelector {...selectorAttributes} />
      </AccordionPane>
    );
  }

  renderShowSlicePercents = () => {
    const {
      onSetShowSlicePercentsInFlyouts,
      vifAuthoring
    } = this.props;

    const inputProps = {
      checked: getShowSlicePercentsInFlyouts(vifAuthoring),
      id: 'show-slice-percents',
      onChange: (event) => onSetShowSlicePercentsInFlyouts(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field" id="show-slice-percents-container">
        <div className="checkbox">
          <input {...inputProps} />
          <label className="inline-label" htmlFor="show-slice-percents">
            <span className="fake-checkbox">
              <span className="icon-checkmark3"></span>
            </span>
            {I18n.t('fields.show_slice_percents.title', { scope })}
          </label>
        </div>
      </div>
    );
  }

  renderScatterChartOrCalendarFlyoutDetails = () => {
    const {
      metadata,
      onAppendSeries,
      onRemoveSeries,
      onSetMeasureColumn,
      vifAuthoring
    } = this.props;

    const series = getSeries(vifAuthoring).map((item, index) => {
      return _.extend({ seriesIndex: index }, item);
    });
    const flyoutSeries = _.filter(series, (item) => {
      return item.type === SERIES_TYPE_FLYOUT;
    });
    const additionalFlyoutColumns = _.map(flyoutSeries, (seriesItem) =>
      _.get(seriesItem, 'dataSource.measure.columnName')
    );
    const selectorProps = {
      addColumnLinkTitle: I18n.t('fields.additional_flyout_values.add_flyout_value', { scope }),
      additionalFlyoutColumns,
      selectedValues: additionalFlyoutColumns,
      columns: getDisplayableColumns(metadata),
      listItemKeyPrefix: 'ScatterChartFlyouts',
      onAdd: (measureColumnName) => onAppendSeries({
        isFlyoutSeries: true,
        isInitialLoad: false,
        measureColumnName,
        rowDisplayUnit: null,
        seriesVariant: null
      }),
      onDelete: (relativeIndex) => onRemoveSeries({
        isFlyoutSeries: true,
        relativeIndex
      }),
      onUpdate: (relativeIndex, columnName) => onSetMeasureColumn({
        columnName,
        isFlyoutSeries: true,
        relativeIndex
        }),
      shouldRenderAddColumnLink: true,
      shouldRenderDeleteColumnLink: true
    };

    return (
      <AccordionPane
        key="details"
        title={I18n.t('subheaders.flyout_details.title', { scope })}>
        <MultiColumnSelector {...selectorProps} />
      </AccordionPane>
    );
  }

  renderLegends = () => {
    const { vifAuthoring } = this.props;
    const shouldDisableAnnotationsLegend = (_.isEmpty(getAnnotations(vifAuthoring)) ||
      !getShowAnnotationsInLegend(vifAuthoring));

    // Currently legends are only available for grouping or multi-series visualizations or pie charts
    if (
        (
          !isGroupingOrHasMultipleNonFlyoutSeries(vifAuthoring) &&
          !isPieChart(vifAuthoring) &&
          !hasReferenceLineLabels(vifAuthoring) &&
          shouldDisableAnnotationsLegend
        ) ||
        (
          isScatterChart(vifAuthoring) &&
          _.isNil(getColorByColumn(vifAuthoring))
        )
      ) {
      return null;
    }

    return (
      <AccordionPane key="legends" title={I18n.t('subheaders.legends.title', { scope })}>
        <LegendsSelector />
      </AccordionPane>
    );
  }

  renderBarChartControls = () => {
    return [this.renderFlyoutUnits(), this.renderFlyoutDetails(), this.renderLegends()];
  }

  renderColumnChartControls = () => {
    return [this.renderFlyoutUnits(), this.renderFlyoutDetails(), this.renderLegends()];
  }

  renderComboChartControls = () => {
    return [this.renderFlyoutUnits(), this.renderFlyoutDetails(), this.renderLegends()];
  }

  renderCalendarControls = () => {
    return [this.renderScatterChartOrCalendarFlyoutDetails()];
  }

  renderScatterChartControls = () => {
    return [this.renderFlyoutUnits(), this.renderScatterChartOrCalendarFlyoutDetails(), this.renderLegends()];
  }

  renderHistogramControls = () => {
    return [this.renderFlyoutUnits(), this.renderLegends()];
  }

  renderPieChartControls = () => {
    return [this.renderFlyoutUnits(), this.renderFlyoutDetails(), this.renderLegends()];
  }

  renderRegionMapControls = () => {
    return this.renderFlyoutUnits();
  }

  renderTimelineChartControls = () => {
    return [
      this.renderFlyoutUnits(),
      this.renderFlyoutDetails(),
      this.renderAnnotations(),
      this.renderLegends()
    ];
  }

  renderFeatureMapControls = () => {
    const { onSetRowInspectorTitleColumnName, vifAuthoring, metadata } = this.props;
    const rowInspectorTitleColumnName = getRowInspectorTitleColumnName(vifAuthoring);
    const columnAttributes = {
      id: 'flyout-title-column',
      options: _.map(getDisplayableColumns(metadata), column => ({
        render: this.renderColumnOption,
        title: column.name,
        type: column.renderTypeName,
        value: column.fieldName
      })),
      onSelection: (option) => onSetRowInspectorTitleColumnName(option.value),
      placeholder: I18n.t('fields.row_inspector_title.no_value', { scope }),
      value: rowInspectorTitleColumnName
    };

    const rowInspector = (
      <AccordionPane key="rowInspector" title={I18n.t('subheaders.row_inspector_title', { scope })}>
        <div className="authoring-field">
          <label className="block-label" htmlFor="flyout-title-column">Column</label>
          <div className="flyout-title-dropdown-container">
            <SingleColumnSelector {...columnAttributes} />
          </div>
        </div>
      </AccordionPane>
    );

    return [this.renderFlyoutUnits(), rowInspector];
  }

  renderColumnOption = (option) => {
    const iconClassForDataType = getIconClassForDataType(option.type);

    return (
      <div className="dataset-column-selector-option">
        <span className={iconClassForDataType}></span> {option.title}
      </div>
    );
  }

  renderEmptyPane = () => {
    return <EmptyPane />;
  }

  render() {
    let configuration;
    const { metadata, vifAuthoring } = this.props;

    if (hasData(metadata)) {
      if (isBarChart(vifAuthoring)) {
        configuration = this.renderBarChartControls();
      } else if (isCalendar(vifAuthoring)) {
        configuration = this.renderCalendarControls();
      } else if (isPieChart(vifAuthoring)) {
        configuration = this.renderPieChartControls();
      } else if (isRegionMap(vifAuthoring)) {
        configuration = this.renderRegionMapControls();
      } else if (isColumnChart(vifAuthoring)) {
        configuration = this.renderColumnChartControls();
      } else if (isComboChart(vifAuthoring)) {
        configuration = this.renderComboChartControls();
      } else if (isScatterChart(vifAuthoring)) {
        configuration = this.renderScatterChartControls();
      } else if (isHistogram(vifAuthoring)) {
        configuration = this.renderHistogramControls();
      } else if (isFeatureMap(vifAuthoring)) {
        configuration = this.renderFeatureMapControls();
      } else if (isTimelineChart(vifAuthoring)) {
        configuration = this.renderTimelineChartControls();
      } else {
        configuration = this.renderEmptyPane();
      }

      return (
        <form>
          <AccordionContainer>
            {configuration}
          </AccordionContainer>
        </form>
      );
    }

    return null;
  }
}

LegendsAndFlyoutsPane.propTypes = {
  metadata: PropTypes.object,
  onAddAnnotation: PropTypes.func,
  onAppendSeries: PropTypes.func,
  onRemoveAnnotation: PropTypes.func,
  onRemoveSeries: PropTypes.func,
  onSetMeasureColumn: PropTypes.func,
  onSetRowInspectorTitleColumnName: PropTypes.func,
  onSetShowAnnotationsInLegend: PropTypes.func,
  onSetShowLegend: PropTypes.func,
  onSetShowSlicePercentsInFlyouts: PropTypes.func,
  onUpdateAnnotation: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onAddAnnotation: addAnnotation,
  onAppendSeries: appendSeries,
  onRemoveAnnotation: removeAnnotation,
  onRemoveSeries: removeSeries,
  onSetMeasureColumn: setMeasureColumn,
  onSetRowInspectorTitleColumnName: setRowInspectorTitleColumnName,
  onSetShowAnnotationsInLegend: setShowAnnotationsInLegend,
  onSetShowLegend: setShowLegend,
  onSetShowSlicePercentsInFlyouts: setShowSlicePercentsInFlyouts,
  onUpdateAnnotation: updateAnnotation
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(LegendsAndFlyoutsPane);
