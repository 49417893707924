import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { setBoundaryColorByColumn, setColorByQuantificationMethod } from '../../../actions';
import { getCurrentMetadata, getNonGeoLocationColumns } from '../../../selectors/metadata';
import { getBoundaryColorByColumn, getMapType, getColorByQuantificationMethod } from '../../../selectors/vifAuthoring';
import I18n from 'common/i18n';
import { getIconClassForDataType } from 'common/views/dataTypeMetadata';
import AutocompleteColumnSelector from 'common/authoring_workflow/components/shared/AutocompleteColumnSelector';
import { normalizeColorByQuantificationMethod } from 'common/authoring_workflow/helpers';

// Constants
import { NULL_COLUMN_NAME } from 'common/authoring_workflow/constants';

const scope = 'shared.visualizations.panes.map_layers.fields.boundary_map_options';

export class BoundaryMapOptionsSelector extends Component {
  getColorByColumnAttributes = () => {
    const {
      metadata,
      onSetBoundaryColorByColumn,
      onSetColorByQuantificationMethod,
      vifAuthoring
    } = this.props;
    const columnOptions = map(getNonGeoLocationColumns(metadata), column => ({
      title: column.name,
      value: column.fieldName,
      type: column.renderTypeName,
      render: this.renderColumnOption
    }));
    const options = [
      { title: I18n.t('no_value', { scope }), value: NULL_COLUMN_NAME },
      ...columnOptions
    ];

    return {
      disabled: columnOptions.length === 0,
      id: 'color-boundaries-by-column-dropdown',
      // Forge Autocomplete does not call onSelect if option.value = null.
      // We pass it as a string so that the user can select the option.
      onSelection: (option) => {
        const quantificationMethod = normalizeColorByQuantificationMethod(
          getBoundaryColorByColumn(vifAuthoring),
          getColorByQuantificationMethod(vifAuthoring),
          option.type
        );
        onSetColorByQuantificationMethod(quantificationMethod);

        if (option.value === NULL_COLUMN_NAME) {
          option.value = null;
        }
        onSetBoundaryColorByColumn(option.value);
      },
      options,
      placeholder: I18n.t('no_value', { scope }),
      value: getBoundaryColorByColumn(vifAuthoring) === null ? NULL_COLUMN_NAME : getBoundaryColorByColumn(vifAuthoring)
    };
  }

  renderColumnOption = (option) => {
    const iconClassForDataType = getIconClassForDataType(option.type);

    return (
      <div className="dataset-column-selector-option">
        <span className={iconClassForDataType}></span> {option.title}
      </div>
    );
  }

  renderBoundaryColorByColumnDropdown = () => {
    return (
      <div className="authoring-field" id="color-boundaries-by-column-selection">
        <label
          className="block-label"
          htmlFor="color-boundaries-by-column-dropdown">
          {I18n.t('boundary_color_by_value', { scope })}
        </label>

        <div className="color-boundaries-by-column-dropdown-container">
          <AutocompleteColumnSelector {...this.getColorByColumnAttributes()} />
        </div>
      </div>
    );
  }

  render() {
    const { vifAuthoring } = this.props;
    const isBoundaryMap = getMapType(vifAuthoring) === 'boundaryMap';

    return isBoundaryMap ? this.renderBoundaryColorByColumnDropdown() : null;
  }
}

BoundaryMapOptionsSelector.propTypes = {
  metadata: PropTypes.object,
  onSetBoundaryColorByColumn: PropTypes.func,
  onSetColorByQuantificationMethod: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetBoundaryColorByColumn: setBoundaryColorByColumn,
  onSetColorByQuantificationMethod: setColorByQuantificationMethod
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(BoundaryMapOptionsSelector);
