import React from 'react';
import { connect } from 'react-redux';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import { ViewColumn } from 'common/types/viewColumn';
import { getForgeIconNameForDataType } from 'common/views/dataTypeMetadata';

import { AuthoringWorkflowState } from 'common/authoring_workflow/reducers/types';
import { getDisplayableColumns, getCurrentMetadata } from 'common/authoring_workflow/selectors/metadata.js';
import TableColumnTooltip from '../TableColumnTooltip';

const scope = 'shared.visualizations.panes.data';

interface Props {
  index: number;
  column: ViewColumn;
  allDisplayableColumns: ViewColumn[];
  onRemoveTableColumn: (index: number) => void;
}
/**
 * A row in the drag and drop container, representing one column in a table visualization.
 */
const ForgeSelectedColumnItem: React.FC<Props> = ({
  column,
  index,
  allDisplayableColumns,
  onRemoveTableColumn
}) => {
  // we use column metadata to populate the column list. if the column is not in the metadata (it may have been deleted from
  // the underlying dataset), then we will default back to whatever data is in the VIF. That way the user will still see
  // the column in the UI, and can remove it.
  const columnMetadata = allDisplayableColumns.find((c) => c.fieldName === column.fieldName) || column;

  const deleteButton = (
    <ForgeIconButton slot="end" dense={true} densityLevel={6} className="delete-selected-column-button">
      <button
        type="button"
        onClick={() => {
          onRemoveTableColumn(index);
        }}
        aria-label={I18n.t('fields.columns.remove', { scope })}
        id={`column-delete-link-${column.fieldName}`}
      >
        <ForgeIcon name="clear" />
      </button>
    </ForgeIconButton>
  );

  const listItem = (
    <div className="table-column-list-item">
      <ForgeIcon
        slot="leading"
        name={getForgeIconNameForDataType(columnMetadata.dataTypeName)}
        className="data-type-icon"
      ></ForgeIcon>
      <span>{columnMetadata.name}</span>
      {deleteButton}
    </div>
  );

  return (
    <TableColumnTooltip id={`column-${index}`} content={columnMetadata.name} key={index}>
      {listItem}
    </TableColumnTooltip>
  );
};

const mapStateToProps = ({ metadataCollection, vifAuthoring }: AuthoringWorkflowState) => ({
  allDisplayableColumns: getDisplayableColumns(getCurrentMetadata(metadataCollection, vifAuthoring))
});

export { ForgeSelectedColumnItem };

export default connect(mapStateToProps)(ForgeSelectedColumnItem);
