// Vendor Imports
import classNames from 'classnames';
import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import * as actions from '../../../actions';
import {
  AGGREGATION_TYPES,
  COLORS,
  COLOR_PALETTES,
  COLORS_WITH_EMPTY_TRANSPARENT_COLOR,
  getMapSliderDebounceMs,
  MAXIMUM_MAP_LAYERS,
  NULL_CHARM_NAME,
  NULL_CATEGORY_VALUE,
  QUANTIFICATION_METHODS,
  RANGE_BUCKET_TYPES,
  VIF_CONSTANTS
} from '../../../constants';
import {
  getCurrentMetadata,
  getCurrentSourceName,
  getCurrentDomainName,
  getDatasetName,
  getDisplayableColumns,
  getFirstOccurringGeoLocationColumn,
  getMapType,
  getValidMeasures,
  hasError,
  hasAtleastOneBooleanColumn,
  isBooleanColumn,
  isBoundaryMapColumn,
  isDimensionTypeNumeric,
  isLineMapColumn,
  isLoading,
  isPointMapColumn,
  isUpdating
} from '../../../selectors/metadata';
import * as selectors from '../../../selectors/vifAuthoring';
import BoundaryMapOptionsSelector from './BoundaryMapOptionsSelector';
import MultiColumnSelector from '../../shared/MultiColumnSelector';
import ColumnAndAggregationSelector from '../../shared/ColumnAndAggregationSelector';
import DimensionSelector from '../../shared/DimensionSelector';
import FlyoutUnitsSelector from '../../shared/FlyoutUnitsSelector';
import SwapControls from '../../shared/SwapControls';
import LineMapOptionsSelector from './LineMapOptionsSelector';
import DebouncedSlider from '../../shared/DebouncedSlider';
import LineWeightPreview from './LineWeightPreview';
import PointSizePreview from '../../shared/PointSizePreview';
import DebouncedInput from '../../shared/DebouncedInput';
import PointMapAggregationSelector from './PointMapAggregationSelector';
import PointMapOptionsSelector from './PointMapOptionsSelector';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import BlockLabel from 'common/components/BlockLabel';
import Dropdown from 'common/components/Dropdown';
import Checkbox from 'common/components/Checkbox';
import ColorAndCharmPicker from 'common/components/ColorAndCharmPicker';
import ColorPalettePicker from 'common/components/ColorPalettePicker';
import ColorPicker from 'common/components/ColorPicker';
import SocrataIcon from 'common/components/SocrataIcon';
import * as assetSelectorConstants from 'common/components/AssetBrowser/lib/constants';
import AssetSelector from 'common/components/AssetSelector';
import I18n from 'common/i18n';
import { getIconClassForDataType } from 'common/views/dataTypeMetadata';
import { hasAnyIncomingFederation } from 'common/federation/utils';
import { getMapColorPalettes, getColorPaletteValue } from 'common/visualizations/helpers/VifSelectors';
import './index.scss';

// Constants
const scope = 'shared.visualizations.panes.map_layers';

export class MapLayersPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLayerMenu: null,
      renamingLayerNameAtIndex: null
    };
  }

  UNSAFE_componentWillMount() { // eslint-disable-line camelcase
    const {
      onSetCurrentMapLayerMode,
      onSetMapLayerName,
      onSetDatasetUid,
      onSetFilters,
      metadata,
      vifAuthoring
    } = this.props;

    if (selectors.getSeriesLength(vifAuthoring) > 1) {
      // Switch to list view when there are multiple layers configured
      onSetCurrentMapLayerMode('list_view');
      onSetDatasetUid(metadata.datasetUid, 'dataTable');
      onSetFilters(vifAuthoring.authoring.filters, 'dataTable');
    } else {
      // Switch directly to map layer options if it is only series
      onSetCurrentMapLayerMode('options_view');

      if (selectors.getMapLayerName(vifAuthoring) === VIF_CONSTANTS.DEFAULT_MAP_LAYER_NAME) {
        onSetMapLayerName(getDatasetName(metadata));
      }
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {  // eslint-disable-line camelcase
    const {
      metadata,
      onSetColorPaletteProperties,
      onSetDimension,
      onSetMapType,
      onSetTriggerAutoSelectGeoLocationColumn,
      vifAuthoring
    } = this.props;
    const nextVifAuthoring = nextProps.vifAuthoring;
    const thisVizType = vifAuthoring.authoring.selectedVisualizationType;
    const nextVizType = nextVifAuthoring.authoring.selectedVisualizationType;
    const thisSeries = selectors.getPrimaryMapLayerSeries(vifAuthoring);
    const nextSeries = selectors.getPrimaryMapLayerSeries(nextVifAuthoring);

    const currentColorPaletteGroupingColumnName = selectors.getColorPaletteGroupingColumnName(vifAuthoring);
    const nextColorPaletteGroupingColumnName = selectors.getColorPaletteGroupingColumnName(nextVifAuthoring);
    const currentMapLegendPrecision = selectors.getMapLegendPrecision(vifAuthoring);
    const nextMapLegendPrecision = selectors.getMapLegendPrecision(nextVifAuthoring);
    const propertyPairs = [
      [thisVizType, nextVizType],
      [_.get(thisSeries, 'mapOptions.colorByQuantificationMethod'), _.get(nextSeries, 'mapOptions.colorByQuantificationMethod')],
      [_.get(thisSeries, 'mapOptions.colorByBucketsCount'), _.get(nextSeries, 'mapOptions.colorByBucketsCount')],
      [_.get(thisSeries, 'mapOptions.midpoint'), _.get(nextSeries, 'mapOptions.midpoint')],
      [currentColorPaletteGroupingColumnName, nextColorPaletteGroupingColumnName],
      [currentMapLegendPrecision, nextMapLegendPrecision],
      [_.get(thisSeries, 'dataSource'), _.get(nextSeries, 'dataSource')],
      [_.get(thisSeries, 'color.palette'), _.get(nextSeries, 'color.palette')]
    ];
    const needsUpdate = _.some(propertyPairs, (pair) => !_.isEqual(...pair));

    // Whenever the properties related to color by (geometry) changes, we need to recompute
    // the buckets for custom color palette. Custom color palette displays the label from each bucket
    // next to a color picker to customize it.
    if (needsUpdate && !_.isNull(nextColorPaletteGroupingColumnName)) {
      onSetColorPaletteProperties();
    }

    if (selectors.getTriggerAutoSelectGeoLocationColumn(nextVifAuthoring)) {
      const columnName = _.get(getFirstOccurringGeoLocationColumn(metadata), 'fieldName', null);

      onSetDimension(columnName);
      onSetMapType(getMapType(metadata, { columnName }));
      onSetTriggerAutoSelectGeoLocationColumn(false);
    }
  };

  onMapSettingsTabNavigation = () => {
    document.getElementById('authoring-basemap-link').click();
  }

  hideMenuAndLayerNameInputsOnOustideClick = (event) => {
    const { activeLayerMenu } = this.state;
    const layerInputSelectorLength = $(event.target).find('.map-layer-input-label').length;

    if (layerInputSelectorLength > 0) {
      this.toggleRenameMode(null);
    }

    if (event.target &&
      event.target.closest &&
      !event.target.closest('.layer-controls-toggle') &&
      !event.target.closest('.layer-control-menu.open')) {
      this.toggleLayerControlsMenu(activeLayerMenu);
    }
  }

  handleDatasetSelected = async (assetData) => {
    const {
      onAppendSeries,
      onSetCurrentMapLayerIndex,
      onSetCurrentMapLayerMode,
      onSetDatasetUid,
      onSetDataSource,
      onSetDomain,
      onSetFilters,
      onSetMapLayerName,
      vifAuthoring
    } = this.props;
    const seriesLength = selectors.getSeriesLength(vifAuthoring);
    const { domain, name } = assetData;

    let datasetUid = assetData.id;

    try {
      // Once dataset is selected from the AssetSelector append the series, set the map layer meta data
      // and transition to layer options view
      onAppendSeries({
        isFlyoutSeries: false,
        isInitialLoad: false,
        measureColumnName: null,
        seriesVariant: null,
        isMapSeries: true
      });
      onSetCurrentMapLayerIndex(seriesLength);
      onSetDataSource(domain, datasetUid, [], true, seriesLength);
      onSetDomain(domain);
      onSetDatasetUid(datasetUid);
      onSetDatasetUid(datasetUid, 'dataTable');
      onSetFilters([], 'dataTable');
      onSetMapLayerName(name);
      onSetCurrentMapLayerMode('options_view');
      this.closeDatasetSelectionModal();
    } catch (error) {
      console.error(`Error while finding NBE of selected dataset ${JSON.stringify(assetData)}`, error);
    }
  }

  closeDatasetSelectionModal = () => {
    this.props.onSetDatasetSelectionModalToggle(false);
  }

  isBoundaryMap = () => {
    const { vifAuthoring, metadata } = this.props;
    const dimension = selectors.getDimension(vifAuthoring);

    return isBoundaryMapColumn(metadata, dimension);
  }

  isLineMap = () => {
    const { vifAuthoring, metadata } = this.props;
    const dimension = selectors.getDimension(vifAuthoring);

    return isLineMapColumn(metadata, dimension);
  }

  isPointMap = () => {
    const { vifAuthoring, metadata } = this.props;
    const dimension = selectors.getDimension(vifAuthoring);

    return isPointMapColumn(metadata, dimension);
  }

  isHeatMap = () => {
    return selectors.getPointAggregation(this.props.vifAuthoring) === 'heat_map';
  }

  isRegionMap = () => {
    return selectors.getPointAggregation(this.props.vifAuthoring) === 'region_map';
  }

  addLayer = () => {
    const { onSetDatasetSelectionModalToggle, vifAuthoring } = this.props;

    if (selectors.getSeriesLength(vifAuthoring) < MAXIMUM_MAP_LAYERS) {
      onSetDatasetSelectionModalToggle(true);
    }
  }

  toggleLayerControlsMenu = (layerIndex) => {
    let { activeLayerMenu } = this.state;

    if (layerIndex === activeLayerMenu) {
      activeLayerMenu = null;
    } else {
      activeLayerMenu = layerIndex;
    }

    this.setState({ activeLayerMenu });
  }

  prepareOutsideClickMouseHandlers = () => {
    let { activeLayerMenu, renamingLayerNameAtIndex } = this.state;

    if (activeLayerMenu === null && renamingLayerNameAtIndex === null) {
      window.removeEventListener('mouseup', this.hideMenuAndLayerNameInputsOnOustideClick);
    } else {
      window.addEventListener('mouseup', this.hideMenuAndLayerNameInputsOnOustideClick);
    }
  }

  toggleEditMode = (layer, layerIndex) => {
    const { onSetCurrentMapLayerIndex, onSetCurrentMapLayerMode, onSetDataSource, onSetDatasetUid, onSetFilters } = this.props;
    const { domain, datasetUid, filters } = layer.dataSource;

    onSetCurrentMapLayerIndex(layerIndex);
    onSetCurrentMapLayerMode('options_view');
    onSetDatasetUid(datasetUid, 'dataTable');
    onSetFilters(filters, 'dataTable');
    onSetDataSource(domain, datasetUid, filters, true, layerIndex);

    this.setState({ activeLayerMenu: null });
  }

  toggleRenameMode = (layerIndex) => {
    this.setState({ activeLayerMenu: null });
    this.setState({ renamingLayerNameAtIndex: layerIndex });
  }

  removeMapLayer = (layerIndex) => {
    const {
      onRemoveMetadataSeries,
      onRemoveSeries,
      onSetCurrentMapLayerIndex,
      vifAuthoring
    } = this.props;
    const primarySeriesIndex = selectors.getPrimaryMapLayerSeriesIndex(vifAuthoring);

    this.setState({ activeLayerMenu: null });

    onRemoveSeries({ isFlyoutSeries: false, relativeIndex: layerIndex, isMapSeries: true });
    onRemoveMetadataSeries(layerIndex);
    onSetCurrentMapLayerIndex(primarySeriesIndex);

    if (primarySeriesIndex > layerIndex) {
      onSetCurrentMapLayerIndex(primarySeriesIndex - 1);
    }
  }

  switchToListView = () => {
    const {
      onSetCurrentMapLayerIndex,
      onSetCurrentMapLayerMode,
      onSetDataSource,
      onSetDatasetUid,
      onSetFilters,
      vifAuthoring
    } = this.props;
    const primarySeriesIndex = selectors.getPrimaryMapLayerSeriesIndex(vifAuthoring);
    const primarySeries = selectors.getPrimaryMapLayerSeries(vifAuthoring);
    const { domain, datasetUid, filters } = primarySeries.dataSource;

    onSetCurrentMapLayerIndex(primarySeriesIndex);
    onSetCurrentMapLayerMode('list_view');
    onSetDatasetUid(datasetUid, 'dataTable');
    onSetFilters(filters, 'dataTable');
    onSetDataSource(domain, datasetUid, filters, true, primarySeriesIndex);
  }

  renderColumnOption = (option) => {
    const iconClassForDataType = getIconClassForDataType(option.type);

    return (
      <div className="dataset-column-selector-option">
        <span className={iconClassForDataType}></span> {option.title}
      </div>
    );
  };

  renderDatasetSelectorTemplate = () => {
    // Columns to use for the "list view" asset selector.
    const assetSelectorColumns = () => {
      if (hasAnyIncomingFederation()) {
        return [
          assetSelectorConstants.COLUMN_TYPE,
          assetSelectorConstants.COLUMN_NAME,
          assetSelectorConstants.COLUMN_SOURCE,
          assetSelectorConstants.COLUMN_LAST_UPDATED_DATE,
          assetSelectorConstants.COLUMN_OWNER,
          assetSelectorConstants.COLUMN_AUDIENCE,
          assetSelectorConstants.COLUMN_ACTIONS
        ];
      } else {
        return [
          assetSelectorConstants.COLUMN_TYPE,
          assetSelectorConstants.COLUMN_NAME,
          assetSelectorConstants.COLUMN_LAST_UPDATED_DATE,
          assetSelectorConstants.COLUMN_OWNER,
          assetSelectorConstants.COLUMN_AUDIENCE,
          assetSelectorConstants.COLUMN_ACTIONS
        ];
      }
    };
    const assetTypes = ['datasets', 'filters'];

    let assetSelectorProps = {
      assetSelector: true,
      baseFilters: {
        assetTypes: assetTypes.join(','),
        published: true
      },
      columns: assetSelectorColumns(),
      onAssetSelected: this.handleDatasetSelected,
      renderStyle: assetSelectorConstants.RENDER_STYLE_LIST,
      title: I18n.t('modal.choose_dataset_heading', { scope })
    };
    const handleClose = () => {
      this.closeDatasetSelectionModal();
    };

    const modalFooterChildren = (
      <div className="common-asset-selector-modal-footer-button-group">
        <div className="authoring-actions">
          <button className="btn btn-sm btn-default cancel" onClick={handleClose}>
            {I18n.t('modal.close', { scope })}
          </button>
        </div>
      </div>
    );

    const defaultAssetSelectorProps = {
      closeOnSelect: false,
      modalFooterChildren,
      onClose: handleClose,
      openInNewTab: true,
      resultsPerPage: 6,
      showBackButton: false,
      withWrapper: true
    };

    assetSelectorProps = _.extend({}, defaultAssetSelectorProps, assetSelectorProps);

    return (
      <div id="dataset-selector">
        <AssetSelector {...assetSelectorProps} />;
      </div>
    );
  }

  renderBoundaryMapOptions = () => {
    const { vifAuthoring } = this.props;
    const boundaryColorByColumn = selectors.getBoundaryColorByColumn(vifAuthoring);

    if (_.isNull(boundaryColorByColumn)) {
      return [this.renderShapeFillColorSelector(), this.renderShapeOutlineColorSelector()];
    }

    const shapeColorPaletteSection = (
      <AccordionPane key="colors" title={I18n.t('subheaders.colors', { scope })}>
        {this.renderColorPaletteSelector(I18n.t('fields.boundary_color.title', { scope }))}
        {this.renderColorByField(boundaryColorByColumn)}
        {this.renderShapeFillOpacitySelector()}
      </AccordionPane>
    );

    return [shapeColorPaletteSection, this.renderShapeOutlineColorSelector()];
  }

  renderColorByBucketsCount = (disabled = false) => {
    const {
      onSetColorByBucketsCount,
      onSetColorPaletteProperties,
      vifAuthoring
    } = this.props;
    const colorByBucketsCount = selectors.getColorByBucketsCount(vifAuthoring);
    const colorByBucketsCountAttributes = {
      disabled,
      id: 'color-by-buckets-count',
      options: _.map(_.range(VIF_CONSTANTS.COLOR_BY_BUCKETS_COUNT.MIN, VIF_CONSTANTS.COLOR_BY_BUCKETS_COUNT.MAX + 1), i => ({ title: i.toString(), value: i.toString() })),
      value: colorByBucketsCount.toString(),
      onSelection: (event) => {
        onSetColorByBucketsCount(event.value);
        onSetColorPaletteProperties();
      }
    };

    return (
      <div className="authoring-field color-by-bucket">
        <label className="block-label" htmlFor="color-by-buckets-count">
          {I18n.t('fields.data_classes.title', { scope })}
        </label>
        <div className="data-classes-dropdown-container">
          <Dropdown {...colorByBucketsCountAttributes} />
        </div>
      </div>
    );
  }

  renderMidpoint = (disabled = false) => {
    const { onSetMidpoint, vifAuthoring } = this.props;
    const midpoint = selectors.getMidpoint(vifAuthoring);
    const labelAttributes = {
      className: 'block-label',
      htmlFor: 'id'
    };

    const inputAttributes = {
      className: 'text-input mid-point-selector',
      disabled,
      id: 'mid-point',
      onChange: (event) => onSetMidpoint(event.target.value),
      type: 'number',
      value: midpoint,
      placeholder: 0
    };

    return (
      <div className="mid-point-container">
        <label {...labelAttributes}>{I18n.t('fields.mid_point.title', { scope })}</label>
        <DebouncedInput {...inputAttributes} />
      </div>
    );
  }

  renderColorByField = (columnName = null) => {
    const { metadata, vifAuthoring } = this.props;
    const selectedQuantificationMethod = selectors.getColorByQuantificationMethod(vifAuthoring);
    const enableDropdown = QUANTIFICATION_METHODS[selectedQuantificationMethod].enableColorByBucketsCount;

    if (!_.isNull(columnName) && isDimensionTypeNumeric(metadata, { columnName })) {
      return (
        <div>
          {this.renderColorByQuantificationMethodSelector()}
          {this.renderColorByBucketsCount(enableDropdown)}
          {this.renderMidpoint(enableDropdown)}
        </div>
      );
    }

    return null;
  }

  renderColorPaletteSelector = () => {
    const {
      onSetColorPalette,
      onSetColorPaletteProperties,
      onSwapColorPalette,
      onUpdateCustomCharmName,
      onUpdateCustomColorPalette,
      metadata,
      vifAuthoring
    } = this.props;
    const quantificationMethod = selectors.getColorByQuantificationMethod(vifAuthoring);
    const isCategoricalQuantificationMethod = quantificationMethod === QUANTIFICATION_METHODS.category.value;
    const isCategorical = isCategoricalQuantificationMethod && !this.isRegionMap();
    const colorPalette = selectors.getMapColorPalette(vifAuthoring);
    const colorPaletteValue = getColorPaletteValue(colorPalette, isCategorical);
    const hasCustomColorPalette = selectors.hasCustomColorPalette(vifAuthoring);

    const colorPalettesWithCustomOption = [
      ...( isCategorical ? COLOR_PALETTES : getMapColorPalettes()),
      { title: I18n.t('shared.visualizations.color_palettes.custom'), value: 'custom' }
    ];
    const isPointAggregation = this.isPointMap() && selectors.getPointAggregation(vifAuthoring) !== 'region_map';
    const colorPaletteAttributes = {
      onSelectColorPalette: (value) => {
        onSetColorPalette(value);
        onSetColorPaletteProperties();
      },
      options: colorPalettesWithCustomOption,
      showCharm: isPointAggregation,
      value: colorPaletteValue
    };

    const colorPaletteGroupingColumnName = selectors.getColorPaletteGroupingColumnName(vifAuthoring);
    const noValueLabel = I18n.t('shared.visualizations.charts.common.no_value');
    const isColorByBooleanColumn = isBooleanColumn(metadata, colorPaletteGroupingColumnName);
    const allCustomColorPalettes = selectors.getCustomColorPaletteForNewGLMaps(vifAuthoring);
    let customColorPalette;

    if (hasCustomColorPalette && quantificationMethod && _.has(allCustomColorPalettes, `${colorPaletteGroupingColumnName}.${quantificationMethod}`)) {
      customColorPalette = _.get(allCustomColorPalettes, `${colorPaletteGroupingColumnName}.${quantificationMethod}`);
    } else {
      customColorPalette = _.get(allCustomColorPalettes, colorPaletteGroupingColumnName);
    }

    const customPaletteIndexs = _.map(customColorPalette, 'index');
    const maximumIndex = _.max(customPaletteIndexs);

    const colorSelectors = _.chain(customColorPalette).
      filter((palette) => palette.index > -1).
      sortBy('index').
      map((paletteItemValue, key) => {
        const { color, charmName, label, id } = paletteItemValue;
        const newLabel = isColorByBooleanColumn && id === NULL_CATEGORY_VALUE ? noValueLabel : label;
        const paletteColorOptions = this.isRegionMap() || this.isBoundaryMap() ?
          COLORS_WITH_EMPTY_TRANSPARENT_COLOR :
          COLORS;
        let customColorPaletteAttributes;
        let customColorPaletteContainer;

        if (isPointAggregation) {
          const currentSeriesIndex = selectors.getCurrentSeriesIndex(vifAuthoring);
          customColorPaletteAttributes = {
            handleColorChange: (selectedColor) =>
              onUpdateCustomColorPalette(selectedColor, newLabel, colorPaletteGroupingColumnName, currentSeriesIndex, quantificationMethod),
            handleCharmChange: (charmName) =>
              onUpdateCustomCharmName(charmName, newLabel, colorPaletteGroupingColumnName, currentSeriesIndex, quantificationMethod),
            color: color,
            charmName: charmName,
            colorPalette: paletteColorOptions
          };

          customColorPaletteContainer = <ColorAndCharmPicker {...customColorPaletteAttributes} />;
        } else {
          const currentSeriesIndex = selectors.getCurrentSeriesIndex(vifAuthoring);
          customColorPaletteAttributes = {
            handleColorChange: (selectedColor) =>
              onUpdateCustomColorPalette(selectedColor, newLabel, colorPaletteGroupingColumnName, currentSeriesIndex ,quantificationMethod),
            value: color,
            palette: paletteColorOptions
          };
          customColorPaletteContainer = <ColorPicker {...customColorPaletteAttributes} />;
        }
        const swapControlAttributes = {
          className: 'custom-color-picker-swap-button',
          onClick: (fromIndex, toIndex) => {
            onSwapColorPalette(colorPaletteGroupingColumnName, fromIndex, toIndex, quantificationMethod);
          },
          index: paletteItemValue.index,
          showUpIcon: (paletteItemValue.index !== 0),
          showDownIcon: (paletteItemValue.index !== maximumIndex)
        };

        return (
          <div className="custom-color-container color-palette-selector" key={`${label}-${key}`}>
            {customColorPaletteContainer}
            <label className="color-value">{_.unescape(label)}</label>
            <span className="custom-swap-controls">
              <SwapControls {...swapControlAttributes} />
            </span>
          </div>
        );
      }).
      value();

    const colorSelectorMarkup = (
      <div className="custom-palette-container">
        {colorSelectors}
      </div>
    );

    return (
      <div>
        <label className="block-label" htmlFor="color-palette">
          {I18n.t('fields.color_palette.title', { scope })}
        </label>
        <ColorPalettePicker {...colorPaletteAttributes} />
        {hasCustomColorPalette && colorSelectorMarkup}
      </div>
    );
  }

  renderDataClassesSelector = (disableDropdown = false) => {
    const {
      onSetColorPaletteProperties,
      onSetNumberOfDataClasses,
      vifAuthoring
    } = this.props;
    const numberOfDataClasses = selectors.getNumberOfDataClasses(vifAuthoring);
    const disabled = _.isUndefined(disableDropdown) ? false : disableDropdown;
    const dataClassesAttributes = {
      disabled,
      id: 'data-classes',
      onSelection: (event) => {
        onSetNumberOfDataClasses(event.value);
        onSetColorPaletteProperties();
      },
      options: _.map(_.range(VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.MIN, VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.MAX + 1), i => ({ title: i.toString(), value: i.toString() })),
      value: numberOfDataClasses.toString()
    };

    return (
      <div className="authoring-field">
        <label className="block-label" htmlFor="data-classes">
          {I18n.t('fields.data_classes.title', { scope })}
        </label>
        <div className="data-classes-dropdown-container">
          <Dropdown {...dataClassesAttributes} />
        </div>
      </div>
    );
  }

  renderDataSelector = () => {
    const geoColumnSelectorTitle = (
      <BlockLabel
        htmlFor="geo-column-selection"
        key="geoColumnSelectorTitle"
        title={I18n.t('fields.geo_column.title', { scope })} />
    );
    const dimensionSelector = (
      <div className="authoring-field" key="dimensionSelector">
        <DimensionSelector />
      </div>
    );

    return [geoColumnSelectorTitle, dimensionSelector];
  }

  renderFlyoutDetails = () => {
    const {
      metadata,
      onAddBasemapFlyoutColumn,
      onChangeAdditionalFlyoutColumn,
      onRemoveBasemapFlyoutColumn,
      vifAuthoring
    } = this.props;
    const scope = 'shared.visualizations.panes.legends_and_flyouts';
    const additionalFlyoutColumns = selectors.getAdditionalFlyoutColumns(vifAuthoring);
    const mapFlyoutTitleColumnName = selectors.getMapFlyoutTitleColumnName(vifAuthoring);
    const attributes = {
      addColumnLinkTitle: I18n.t('fields.additional_flyout_values.add_flyout_value', { scope }),
      additionalFlyoutColumns,
      columns: getDisplayableColumns(metadata),
      listItemKeyPrefix: 'AdditionalFlyoutValues',
      onAdd: onAddBasemapFlyoutColumn,
      onDelete: onRemoveBasemapFlyoutColumn,
      onUpdate: onChangeAdditionalFlyoutColumn,
      selectedValues: [mapFlyoutTitleColumnName, ...additionalFlyoutColumns],
      shouldRenderAddColumnLink: true,
      shouldRenderDeleteColumnLink: true
    };

    return (
      <AccordionPane
        key="details"
        title={I18n.t('subheaders.flyout_details.title', { scope })}>
        {this.renderFlyoutTitle()}
        <label>
          {I18n.translate('subheaders.additional_flyout_values', { scope })}
        </label>
        <MultiColumnSelector {...attributes} />
      </AccordionPane>
    );
  }

  renderFlyoutOptions = () => {
    if (this.isPointMap()) {
      if (this.isHeatMap()) {
        return;
      }

      return this.isRegionMap() ?
        [this.renderFlyoutUnits(), this.renderRegionMapFlyoutDetails()] :
        [this.renderFlyoutUnits(), this.renderFlyoutDetails()];
    }

    if (this.isBoundaryMap() || this.isLineMap()) {
      return [this.renderFlyoutDetails()];
    }
  }

  renderFlyoutTitle = () => {
    const scope = 'shared.visualizations.panes.legends_and_flyouts';
    const {
      metadata,
      onSetMapFlyoutTitleColumnName,
      vifAuthoring
    } = this.props;
    const mapFlyoutTitleColumnName = selectors.getMapFlyoutTitleColumnName(vifAuthoring);
    const columnOptions = _.map(getDisplayableColumns(metadata), column => ({
      render: this.renderColumnOption,
      title: column.name,
      type: column.renderTypeName,
      value: column.fieldName
    }));
    const options = [
      {
        title: I18n.t('fields.maps_flyout_title.no_value', { scope }),
        value: null
      },
      ...columnOptions
    ];
    const columnAttributes = {
      id: 'flyout-title-column',
      onSelection: (option) => onSetMapFlyoutTitleColumnName(option.value),
      options,
      placeholder: I18n.t('fields.maps_flyout_title.no_value', { scope }),
      value: mapFlyoutTitleColumnName
    };

    return (
      <div className="authoring-field">
        <label className="block-label" htmlFor="flyout-title-column">
          {I18n.t('fields.maps_flyout_title.title', { scope })}
        </label>
        <div className="flyout-title-dropdown-container">
          <Dropdown {...columnAttributes} />
        </div>
      </div>
    );
  }

  renderFlyoutUnits = () => {
    const scope = 'shared.visualizations.panes.legends_and_flyouts';

    return (
      <AccordionPane
        key="units"
        title={I18n.t('subheaders.flyout_units.title', { scope })}>
        <p className="authoring-field-description units-description">
          <small>{I18n.t('subheaders.flyout_units.description_for_maps', { scope })}</small>
        </p>
        <FlyoutUnitsSelector metadata={this.props.metadata} />
      </AccordionPane>
    );
  }

  renderLineMapOptions = () => {
    const { vifAuthoring } = this.props;
    const colorLinesByColumn = selectors.getColorLinesByColumn(vifAuthoring);
    const colorLabelText = I18n.t('fields.line_color.title', { scope });
    const colorControls = (
      <AccordionPane key="colors" title={I18n.t('subheaders.colors', { scope })}>
        {_.isNull(colorLinesByColumn) ?
          this.renderPrimaryColorSelector(colorLabelText) :
          this.renderColorPaletteSelector(colorLabelText)}
        {this.renderColorByField(colorLinesByColumn)}
        {this.renderLineColorOpacitySelector()}
      </AccordionPane>
    );

    return [
      colorControls,
      this.renderLineMapWeightSelector()
    ];
  }

  renderLineMapWeightSelector = () => {
    const { vifAuthoring } = this.props;
    const isWeighLinesByColumnSelected = selectors.getWeighLinesByColumn(vifAuthoring);
    let LineMapWeightControls = null;

    if (isWeighLinesByColumnSelected) {
      const { onSetMaximumLineWeight, onSetMinimumLineWeight } = this.props;
      const minimumLineWeight = selectors.getMinimumLineWeight(vifAuthoring);
      const maximumLineWeight = selectors.getMaximumLineWeight(vifAuthoring);
      const minimumLineWeightAttributes = {
        delay: getMapSliderDebounceMs(),
        id: 'minimum-line-weight',
        onChange: (value) => onSetMinimumLineWeight(_.round(value, 2)),
        rangeMax: VIF_CONSTANTS.LINE_WEIGHT.MAX,
        rangeMin: VIF_CONSTANTS.LINE_WEIGHT.MIN,
        step: VIF_CONSTANTS.LINE_WEIGHT.STEP,
        value: minimumLineWeight
      };
      const maximumLineWeightAttributes = {
        delay: getMapSliderDebounceMs(),
        id: 'maximum-line-weight',
        onChange: (value) => onSetMaximumLineWeight(_.round(value, 2)),
        rangeMax: VIF_CONSTANTS.LINE_WEIGHT.MAX,
        rangeMin: VIF_CONSTANTS.LINE_WEIGHT.MIN,
        step: VIF_CONSTANTS.LINE_WEIGHT.STEP,
        value: maximumLineWeight
      };

      LineMapWeightControls = (
        <div className="line-weight-min-max-selection-container">
          <div className="authoring-field">
            <label
              className="block-label"
              htmlFor="minimum-line-weight">
              {I18n.t('fields.line_weight.minimum', { scope })}
            </label>
            <div className="debounced-slider-with-preview">
              <div className="slider-container">
                <DebouncedSlider {...minimumLineWeightAttributes} />
              </div>
              <LineWeightPreview lineWeight={minimumLineWeight} />
            </div>
          </div>

          <div className="authoring-field">
            <label
              className="block-label"
              htmlFor="maximum-line-weight">
              {I18n.t('fields.line_weight.maximum', { scope })}
            </label>
            <div className="debounced-slider-with-preview">
              <div className="slider-container">
                <DebouncedSlider {...maximumLineWeightAttributes} />
              </div>
              <LineWeightPreview lineWeight={maximumLineWeight} />
            </div>
          </div>

          {this.renderDataClassesSelector()}
        </div>
      );
    } else {
      const { onSetLineWeight } = this.props;
      const lineWeight = selectors.getLineWeight(vifAuthoring);
      const lineWeightAttributes = {
        delay: getMapSliderDebounceMs(),
        id: 'line-weight',
        onChange: (value) => onSetLineWeight(_.round(value, 2)),
        rangeMax: VIF_CONSTANTS.LINE_WEIGHT.MAX,
        rangeMin: VIF_CONSTANTS.LINE_WEIGHT.MIN,
        step: VIF_CONSTANTS.LINE_WEIGHT.STEP,
        value: lineWeight
      };

      LineMapWeightControls = (
        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="line-weight">
            {I18n.t('fields.line_weight.title', { scope })}
          </label>
          <DebouncedSlider {...lineWeightAttributes} />
        </div>
      );
    }

    return (
      <AccordionPane key="lineWeightControls" title={I18n.t('subheaders.line_weight', { scope })}>
        {LineMapWeightControls}
      </AccordionPane>
    );
  }

  renderLineColorOpacitySelector = () => {
    const { onSetLineColorOpacity, vifAuthoring } = this.props;
    const lineColorOpacity = selectors.getLineColorOpacity(vifAuthoring);
    const lineColorOpacityAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'line-color-opacity',
      onChange: (value) => onSetLineColorOpacity(_.round(value, 2)),
      rangeMax: VIF_CONSTANTS.LINE_COLOR_OPACITY.MAX,
      rangeMin: VIF_CONSTANTS.LINE_COLOR_OPACITY.MIN,
      step: VIF_CONSTANTS.LINE_COLOR_OPACITY.STEP,
      value: lineColorOpacity
    };

    return (
      <div className="authoring-field">
        <label
          className="block-label"
          htmlFor="line-color-opacity">
          {I18n.t('fields.line_color_opacity.title', { scope })}
        </label>
        <DebouncedSlider {...lineColorOpacityAttributes} />
      </div>
    );
  }

  renderLayerName = (layerName, layer, layerIndex) => {
    return (
      <div className="map-layer-item" onClick={() => this.toggleEditMode(layer, layerIndex)}>
        <h6>
          <SocrataIcon name="map" />
          {layerName}
        </h6>
      </div>
    );
  }

  renderLayerInputField = (layerName, layerIndex) => {
    const {
      onSetCurrentMapLayerIndex,
      onSetMapLayerName
    } = this.props;

    const onKeyDownLayerName = (event) => {
      if (event.key === 'Enter') {
        this.setState({ renamingLayerNameAtIndex: null });
      }
    };

    const inputAttributes = {
      autoFocus: 'autoFocus',
      className: 'map-layer-input-label',
      onChange: (event) => {
        onSetCurrentMapLayerIndex(layerIndex);
        onSetMapLayerName(event.currentTarget.value);
      },
      onFocus: (event) => $(event.target).select(),
      onKeyDown: onKeyDownLayerName,
      type: 'text',
      value: layerName
    };

    return (
      <div className="map-layer-item">
        <h6>
          <SocrataIcon name="map" />
          <input {...inputAttributes} />
        </h6>
      </div>
    );
  }

  renderMapLayerList = () => {
    const { activeLayerMenu, renamingLayerNameAtIndex } = this.state;
    const {
      onSetMapLayerVisible,
      onSwapMetaDataCollectionSeries,
      onSwapSeries,
      vifAuthoring,
    } = this.props;

    const series = selectors.getSeries(vifAuthoring);
    const seriesLength = series.length;
    const getRemoveMapLayerLink = (layerIndex, isPrimary) => {
      // Primary layer should never be allowed to be removed, only additional layers are removable
      if (isPrimary) {
        return (
          <li>
            <button disabled>
              {I18n.t('add_layer.delete', { scope })}
            </button>
          </li>
        );
      }

      return (
        <li>
          <button onClick={() => this.removeMapLayer(layerIndex)}>
            {I18n.t('add_layer.delete', { scope })}
          </button>
        </li>
      );
    };
    const onClickSwapLayer = (layerIndex, changeIndex) => {
      this.setState({ renamingLayerNameAtIndex: null });
      onSwapSeries(layerIndex, changeIndex);
      // TODO: Behavior of TablePreview TBD
      // For now, switching layers will only show the primary layer
      // onSwapMetaDataCollectionSeries(layerIndex, changeIndex);
    };
    const primaryLabel = <span className="primary-label">{I18n.t('add_layer.primary', { scope })}</span>;
    const renderSwapLayerControls = (layerIndex, layer) => {
      // Cannot move down if the layer is last
      const moveDownDisabled = layerIndex === seriesLength - 1;
      // Cannot move up if the layer is first
      const moveUpDisabled = layerIndex === 0;

      return (
        <div className="swap-controls">
          <button
            className={classNames('layer-swap-button', { disabled: moveUpDisabled })}
            onClick={() => !moveUpDisabled && onClickSwapLayer(layerIndex, layerIndex - 1)}>
            <span className="icon-arrow-up2"></span>
          </button>
          <button
            className={classNames('layer-swap-button', { disabled: moveDownDisabled })}
            onClick={() => !moveDownDisabled && onClickSwapLayer(layerIndex, layerIndex + 1)}>
            <span className="icon-arrow-down2"></span>
          </button>
        </div>
      );
    };
    const getDatasetLink = (layer, layerIndex) => {
      const { datasetUid } = layer.dataSource;
      const domain = getCurrentDomainName(layer);
      const source = getCurrentSourceName(this.props.metadataCollection, layerIndex);

      return <a href={`https://${domain}/d/${datasetUid}`} target="_blank" rel="noreferrer">{source}</a>;
    };

    this.prepareOutsideClickMouseHandlers();

    return series.map((layer, layerIndex) => {
      const isPrimary = layer.primary;
      const layerName = _.get(layer, 'dataSource.name', VIF_CONSTANTS.DEFAULT_MAP_LAYER_NAME);
      const renderedLayerName = _.isEqual(renamingLayerNameAtIndex, layerIndex) ?
        this.renderLayerInputField(layerName, layerIndex) :
        this.renderLayerName(layerName, layer, layerIndex);

      return (
        <li key={`layer-${layerIndex}`} className={classNames({ primary: isPrimary })}>
          {renderedLayerName}
          <p className="source-text">
            {I18n.t('add_layer.source', { scope })} {getDatasetLink(layer, layerIndex)}
          </p>
          {isPrimary && primaryLabel}
          <div className="layer-controls">
            <button
              className="layer-visibility-toggle"
              onClick={() => onSetMapLayerVisible(layerIndex, !layer.visible)}>
              <span className={classNames({ 'icon-eye': layer.visible, 'icon-eye-blocked': !layer.visible })}></span>
            </button>
            <button
              className={classNames('layer-controls-toggle', { active: activeLayerMenu === layerIndex })}
              onClick={() => this.toggleLayerControlsMenu(layerIndex)}>
              <span className="icon-kebab"></span>
            </button>
            <ul className={classNames('layer-control-menu', { open: activeLayerMenu === layerIndex })}>
              <li>
                <button onClick={() => this.toggleEditMode(layer, layerIndex)}>
                  {I18n.t('add_layer.edit', { scope })}
                </button>
              </li>
              <li>
                <button className="layer-rename-button" onClick={() => this.toggleRenameMode(layerIndex)}>
                  {I18n.t('add_layer.rename', { scope })}
                </button>
              </li>
              <li>
                <button onClick={() => onSetMapLayerVisible(layerIndex, !layer.visible)}>
                  {I18n.t(`add_layer.${layer.visible ? 'hide' : 'show'}`, { scope })}
                </button>
              </li>
              {getRemoveMapLayerLink(layerIndex, isPrimary)}
            </ul>
          </div>
          {seriesLength > 1 && renderSwapLayerControls(layerIndex, layer)}
        </li>
      );
    });
  }

  renderListView = () => {
    const { vifAuthoring } = this.props;
    const basemapType = selectors.getBasemapType(vifAuthoring);
    const disableAddLayers = (selectors.getSeriesLength(vifAuthoring) >= MAXIMUM_MAP_LAYERS);

    return (
      <div className="map-layers-list-view">
        <button
          className={classNames('add-layer-btn btn btn-primary btn-inverse', { disabled: disableAddLayers })}
          onClick={this.addLayer}>
          <span className="icon-plus3"></span>
          {I18n.t('add_layer.title', { scope })}
        </button>
        <ul className="map-layer-list">
          {this.renderMapLayerList()}
          <li key="baseMap" onClick={this.onMapSettingsTabNavigation}>
            <div className="map-layer-item">
              <h6>{I18n.t('add_layer.basemap', { scope })}</h6>
              <p className="source-text">{basemapType}</p>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  renderMapLayersPaneContent = () => {
    const { metadata, vifAuthoring } = this.props;
    const isDimensionColumnSelected = !_.isNull(selectors.getColorPaletteGroupingColumnName(vifAuthoring));
    const isListViewMode = selectors.getCurrentMapLayerEditView(vifAuthoring) === 'list_view';
    let mapLayersPaneContent = null;

    if (isListViewMode) {
      return this.renderListView();
    }

    if (hasError(metadata)) {
      mapLayersPaneContent = this.renderMetadataError();
    } else if (isLoading(metadata)) {
      mapLayersPaneContent = this.renderMetadataLoading();
    } else {
      mapLayersPaneContent = (
        <AccordionContainer>
          <AccordionPane title={I18n.t('subheaders.data_selection', { scope })}>
            {this.renderDataSelector()}
            {this.renderMapOptionsSelector()}
          </AccordionPane>
          {this.isPointMap() && this.renderPointAggregationOptions()}
          {this.isPointMap() && this.renderPointMapOptions()}
          {this.isRegionMap() && this.renderShapeOutlineColorSelector()}
          {this.isLineMap() && this.renderLineMapOptions()}
          {this.isBoundaryMap() && this.renderBoundaryMapOptions()}
          {this.renderFlyoutOptions()}
          {isDimensionColumnSelected && this.renderShowLegendOptions()}
          {this.renderAdvancedOptions()}
        </AccordionContainer>
      );
    }

    return (
      <form id="mapLayersPane">
        <div className="map-layer-options">
          <div className="layer-options-header">
            <a id="switch-to-list-view-link" href="#" onClick={this.switchToListView}>
              <span className="icon-arrow-prev"></span>
              {I18n.t('add_layer.layer_list', { scope })}
            </a>
            <h4>{selectors.getMapLayerName(vifAuthoring)}</h4>
          </div>
          {mapLayersPaneContent}
        </div>
      </form>
    );
  }

  renderRegionMapFlyoutDetails = () => {
    const {
      metadata,
      onAddRegionMapFlyoutColumnAndAggregation,
      onChangeRegionMapFlyoutColumnAndAggregation,
      onRemoveRegionMapFlyoutColumnAndAggregation,
      vifAuthoring
    } = this.props;
    const columnAndAggregationValues = selectors.getRegionMapFlyoutColumnAndAggregations(vifAuthoring);
    const scope = 'shared.visualizations.panes';
    const validMeasures = getValidMeasures(metadata);
    const columns = [
      {
        title: I18n.t('data.fields.measure.no_value', { scope }),
        value: null
      },
      ...validMeasures.map((validMeasure) => ({
        title: validMeasure.name,
        value: validMeasure.fieldName,
        type: validMeasure.renderTypeName,
        render: this.renderColumnOption
      }))
    ];
    const attributes = {
      aggregations: AGGREGATION_TYPES,
      columns: columns,
      onAdd: (value) => {
        onAddRegionMapFlyoutColumnAndAggregation({
          aggregation: AGGREGATION_TYPES[0].type,
          column: value
        });
      },
      onDelete: onRemoveRegionMapFlyoutColumnAndAggregation,
      onUpdate: onChangeRegionMapFlyoutColumnAndAggregation,
      showNewInProgressColumnAndAggregateSelector: false,
      showAlreadySelectedColumns: false,
      shouldRenderAddLink: true,
      shouldRenderDeleteLink: true,
      values: columnAndAggregationValues
    };

    return (
      <AccordionPane
        key="details"
        title={I18n.t('legends_and_flyouts.subheaders.flyout_details.title', { scope })}>
        <ColumnAndAggregationSelector {...attributes} />
      </AccordionPane>
    );
  }

  renderRangeBucketType = () => {
    const {
      onSetColorPaletteProperties,
      onSetRangeBucketType,
      vifAuthoring
    } = this.props;
    const selectedRangeBucketType = selectors.getRangeBucketType(vifAuthoring);
    const id = 'range-bucket-types';
    const rangeBucketTypeAttributes = {
      id,
      onSelection: (event) => {
        onSetRangeBucketType(event.value);
        onSetColorPaletteProperties();
      },
      options: _.map(RANGE_BUCKET_TYPES, rangeBucketType => ({
        title: rangeBucketType.title,
        value: rangeBucketType.value
      })),
      value: selectedRangeBucketType
    };

    return (
      <div className="authoring-field range-bucket-type">
        <label className="block-label" htmlFor={id}>
          {I18n.t('subheaders.range_bucket_type', { scope })}
        </label>
        <Dropdown {...rangeBucketTypeAttributes} />
      </div>
    );
  }

  renderCastNullAsFalse = () => {
    const { onSetCastNullAsFalse, onSetColorPaletteProperties, metadata, vifAuthoring } = this.props;
    const isCastNullAsFalseInSeries = selectors.getCastNullAsFalseInSeries(vifAuthoring);
    const colorPaletteGroupingColumnName = selectors.getColorPaletteGroupingColumnName(vifAuthoring);
    const mapFlyoutTitleColumnName = selectors.getMapFlyoutTitleColumnName(vifAuthoring);
    const additionalFlyoutColumns = selectors.getAdditionalFlyoutColumns(vifAuthoring);
    const isColorByBooleanColumn = isBooleanColumn(metadata, colorPaletteGroupingColumnName);
    const isFlyoutTitleBooleanColumn = isBooleanColumn(metadata, mapFlyoutTitleColumnName);
    const hasAtleastOneAdditionalFlyoutBooleanColumn = hasAtleastOneBooleanColumn(metadata, additionalFlyoutColumns);
    const scope = 'shared.visualizations.panes.presentation';

    if (isColorByBooleanColumn || isFlyoutTitleBooleanColumn || hasAtleastOneAdditionalFlyoutBooleanColumn) {
      const checkboxAttributes = {
        checked: isCastNullAsFalseInSeries,
        disabled: false,
        id: 'cast-null-as-false-in-series',
        onChange: (event) => {
          onSetCastNullAsFalse(event.target.checked);
          onSetColorPaletteProperties();
        }
      };

      return (
        <Checkbox {...checkboxAttributes}>
          <span>{I18n.t('fields.show_nulls_as_false.title', { scope })}</span>
        </Checkbox>
      );
    }
  }

  renderAdvancedOptions = () => {
    return (
      <AccordionPane key="advanced-options" title={I18n.t('subheaders.advanced_options', { scope })}>
        {this.renderSimplificationLevelSelector()}
        {this.renderCastNullAsFalse()}
      </AccordionPane>
    );
  }

  renderSimplificationLevelSelector = () => {
    const { onSetSimplificationLevel, vifAuthoring } = this.props;
    const simplificationLevel = selectors.getSimplificationLevel(vifAuthoring);
    const currentSeriesIndex = selectors.getCurrentSeriesIndex(vifAuthoring);
    const simplificationLevelAttributes = {
      id: 'simplification-level',
      onSelection: (simplificationLevelOption) => {
        onSetSimplificationLevel(currentSeriesIndex, simplificationLevelOption.value);
      },
      options: VIF_CONSTANTS.SIMPLIFICATION_LEVEL.OPTIONS,
      value: simplificationLevel
    };

    return (
      <div className="authoring-field">
        <label
          className="block-label"
          htmlFor="simplification-level">
          {I18n.t('fields.simplification_level.title', { scope })}
        </label>
        <Dropdown {...simplificationLevelAttributes} />
      </div>
    );
  }

  renderMapOptionsSelector = () => {
    return (
      <div className="authoring-field">
        {this.isPointMap() && <PointMapOptionsSelector />}
        {this.isLineMap() && <LineMapOptionsSelector />}
        {this.isBoundaryMap() && <BoundaryMapOptionsSelector />}
      </div>
    );
  }

  renderMetadataError = () => {
    return (
      <div className="metadata-error alert error">
        <strong>{I18n.t('uhoh', { scope })}</strong> {I18n.t('loading_metadata_error', { scope })}
      </div>
    );
  }

  renderMetadataLoading = () => {
    const metadataI18nKey = isUpdating(this.props.metadata) ? 'updating_metadata' : 'loading_metadata';

    return (
      <div className="alert">
        <div className="metadata-loading">
          <span className="spinner-default metadata-loading-spinner"></span> {I18n.t(metadataI18nKey, { scope })}
        </div>
      </div>
    );
  }

  renderPointAggregationOptions = () => {
    return (
      <AccordionPane key="point-aggregation" title={I18n.t('subheaders.point_aggregation', { scope })}>
        <PointMapAggregationSelector />
      </AccordionPane>
    );
  }

  renderPointMapOptions = () => {
    const { vifAuthoring } = this.props;
    const colorPointsByColumn = selectors.getColorPointsByColumn(vifAuthoring);
    const rangeBucketType = selectors.getRangeBucketType(vifAuthoring);
    const disabledMidpoint = rangeBucketType === RANGE_BUCKET_TYPES.jenks.value;

    if (this.isHeatMap()) {
      return null;
    }

    const isRegionMap = this.isRegionMap();
    const colorLabelText = I18n.t('fields.point_color.title', { scope });
    const colorControls = (
      <AccordionPane key="colors" title={I18n.t('subheaders.colors', { scope })}>
        {_.isNull(colorPointsByColumn) && !isRegionMap ?
          this.renderPrimaryColorSelector(colorLabelText) :
          this.renderColorPaletteSelector(colorLabelText)}
        {!isRegionMap && this.renderColorByField(colorPointsByColumn)}
        {!isRegionMap && this.renderPointOpacitySelector()}
        {isRegionMap && this.renderRangeBucketType()}
        {isRegionMap && this.renderColorByBucketsCount()}
        {isRegionMap && this.renderMidpoint(disabledMidpoint)}
        {isRegionMap && this.renderShapeFillOpacitySelector()}
      </AccordionPane>
    );

    if (isRegionMap) {
      return [colorControls];
    }

    return [
      colorControls,
      this.renderPointMapSizeSelector()
    ];
  }

  renderPointMapSizeSelector = () => {
    const { vifAuthoring } = this.props;
    const isResizePointsByColumnSelected = selectors.getResizePointsByColumn(vifAuthoring);
    let pointMapSizeControls = null;
    const isCharmConfigured = selectors.getCharmName(vifAuthoring) !== NULL_CHARM_NAME ||
      selectors.getColorPalette()(vifAuthoring) === 'custom';

    if (isResizePointsByColumnSelected) {
      const { onSetMaximumPointSize, onSetMinimumPointSize } = this.props;
      const minimumPointSize = selectors.getMinimumPointSize(vifAuthoring);
      const maximumPointSize = selectors.getMaximumPointSize(vifAuthoring);
      const minimumPointSizeAttributes = {
        delay: getMapSliderDebounceMs(),
        id: 'minimum-point-size',
        onChange: (value) => onSetMinimumPointSize(_.round(value, 2)),
        rangeMax: VIF_CONSTANTS.POINT_MAP_MIN_POINT_SIZE.MAX,
        rangeMin: isCharmConfigured ?
          VIF_CONSTANTS.POINT_MAP_MIN_POINT_SIZE.DEFAULT :
          VIF_CONSTANTS.POINT_MAP_MIN_POINT_SIZE.MIN,
        step: VIF_CONSTANTS.POINT_MAP_MIN_POINT_SIZE.STEP,
        value: minimumPointSize
      };
      const maximumPointSizeAttributes = {
        delay: getMapSliderDebounceMs(),
        id: 'maximum-point-size',
        onChange: (value) => onSetMaximumPointSize(_.round(value, 2)),
        rangeMin: isCharmConfigured ?
          VIF_CONSTANTS.POINT_MAP_MIN_POINT_SIZE.DEFAULT :
          VIF_CONSTANTS.POINT_MAP_MAX_POINT_SIZE.MIN,
        rangeMax: VIF_CONSTANTS.POINT_MAP_MAX_POINT_SIZE.MAX,
        step: VIF_CONSTANTS.POINT_MAP_MIN_POINT_SIZE.STEP,
        value: maximumPointSize
      };

      pointMapSizeControls = (
        <div className="point-size-min-max-selection-container">
          <div className="authoring-field">
            <label
              className="block-label"
              htmlFor="minimum-point-size">
              {I18n.t('fields.point_size.minimum', { scope })}
            </label>
            <div className="debounced-slider-with-preview">
              <div className="slider-container">
                <DebouncedSlider {...minimumPointSizeAttributes} />
              </div>
              <PointSizePreview pointSize={minimumPointSize} />
            </div>
          </div>

          <div className="authoring-field">
            <label
              className="block-label"
              htmlFor="maximum-point-size">
              {I18n.t('fields.point_size.maximum', { scope })}
            </label>
            <div className="debounced-slider-with-preview">
              <div className="slider-container">
                <DebouncedSlider {...maximumPointSizeAttributes} />
              </div>
              <PointSizePreview pointSize={maximumPointSize} />
            </div>
          </div>

          {this.renderDataClassesSelector()}
        </div>
      );
    } else {
      const { onSetPointSize } = this.props;
      const pointSize = selectors.getPointSize(vifAuthoring);
      const pointSizeAttributes = {
        delay: getMapSliderDebounceMs(),
        id: 'point-size',
        onChange: (value) => onSetPointSize(_.round(value, 2)),
        rangeMax: VIF_CONSTANTS.POINT_MAP_POINT_SIZE.MAX,
        rangeMin: isCharmConfigured ?
          VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT :
          VIF_CONSTANTS.POINT_MAP_POINT_SIZE.MIN,
        step: VIF_CONSTANTS.POINT_MAP_POINT_SIZE.STEP,
        value: pointSize
      };

      pointMapSizeControls = (
        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="point-size">
            {I18n.t('fields.point_size.title', { scope })}
          </label>
          <div id="point-size-slider-container">
            <DebouncedSlider {...pointSizeAttributes} />
          </div>
        </div>
      );
    }

    return (
      <AccordionPane key="pointSizeControls" title={I18n.t('subheaders.point_size', { scope })}>
        {pointMapSizeControls}
      </AccordionPane>
    );
  }

  renderPointOpacitySelector = () => {
    const { onSetPointOpacity, vifAuthoring } = this.props;
    const pointOpacity = selectors.getPointOpacity(vifAuthoring);
    const pointOpacityAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'point-opacity',
      onChange: (value) => onSetPointOpacity(_.round(value, 2)),
      rangeMax: VIF_CONSTANTS.POINT_OPACITY.MAX,
      rangeMin: VIF_CONSTANTS.POINT_OPACITY.MIN,
      step: VIF_CONSTANTS.POINT_OPACITY.STEP,
      value: pointOpacity
    };

    return (
      <div className="authoring-field">
        <label
          className="block-label"
          htmlFor="point-opacity">{I18n.t('fields.point_opacity.title', { scope })}</label>
        <div id="point-opacity-slider-container">
          <DebouncedSlider {...pointOpacityAttributes} />
        </div>
      </div>
    );
  }

  renderPrimaryColorSelector = (labelText) => {
    const { onSetCharmName, onSetPrimaryColor, vifAuthoring } = this.props;
    const primaryColor = selectors.getPrimaryColor(vifAuthoring);
    const charmName = selectors.getCharmName(vifAuthoring);
    const currentSeriesIndex = selectors.getCurrentSeriesIndex(vifAuthoring);
    let colorSelectorContainer;
    let colorPickerAttributes;

    if (this.isPointMap()) {
      colorPickerAttributes = {
        handleColorChange: (primaryColor) => onSetPrimaryColor(currentSeriesIndex, primaryColor),
        handleCharmChange: (charmName) => onSetCharmName(currentSeriesIndex, charmName),
        colorPalette: COLORS,
        color: primaryColor,
        charmName: charmName
      };
      colorSelectorContainer = <ColorAndCharmPicker {...colorPickerAttributes} />;
    } else {
      colorPickerAttributes = {
        handleColorChange: (primaryColor) => onSetPrimaryColor(currentSeriesIndex, primaryColor),
        palette: COLORS,
        value: primaryColor
      };
      colorSelectorContainer = <ColorPicker {...colorPickerAttributes} />;
    }

    return (
      <div id="primary-color-picker">
        <label className="block-label">{labelText}</label>
        {colorSelectorContainer}
      </div>
    );
  }

  renderShapeFillColorSelector = () => {
    const { onSetShapeFillColor, vifAuthoring } = this.props;
    const shapeFillColor = selectors.getShapeFillColor(vifAuthoring);
    const shapeFillColorAttributes = {
      id: 'shape-fill-color',
      handleColorChange: onSetShapeFillColor,
      palette: COLORS,
      value: shapeFillColor
    };

    return (
      <AccordionPane key="shapeFillColors" title={I18n.t('subheaders.colors', { scope })}>
        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="shape-fill-color">
            {I18n.t('fields.shape_fill_color.title', { scope })}
          </label>
          <ColorPicker {...shapeFillColorAttributes} />
        </div>
        {this.renderShapeFillOpacitySelector()}
      </AccordionPane>
    );
  }

  renderShapeFillOpacitySelector = () => {
    const { onSetShapeFillOpacity, vifAuthoring } = this.props;
    const shapeFillOpacity = selectors.getShapeFillOpacity(vifAuthoring);
    const shapeFillOpacityAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'shape-fill-opacity',
      onChange: (value) => onSetShapeFillOpacity(_.round(value, 2)),
      rangeMax: VIF_CONSTANTS.SHAPE_FILL_OPACITY.MAX,
      rangeMin: VIF_CONSTANTS.SHAPE_FILL_OPACITY.MIN,
      step: VIF_CONSTANTS.SHAPE_FILL_OPACITY.STEP,
      value: shapeFillOpacity
    };

    return (
      <div className="authoring-field">
        <label
          className="block-label"
          htmlFor="shape-fill-opacity">
          {I18n.t('fields.shape_fill_opacity.title', { scope })}
        </label>
        <DebouncedSlider {...shapeFillOpacityAttributes} />
      </div>
    );
  }

  renderShapeOutlineColorSelector = () => {
    const {
      onSetShapeOutlineColor,
      onSetShapeOutlineWidth,
      vifAuthoring
    } = this.props;
    const shapeOutlineColor = selectors.getShapeOutlineColor(vifAuthoring);
    const shapeOutlineWidth = selectors.getShapeOutlineWidth(vifAuthoring);
    const shapeOutlineColorAttributes = {
      id: 'shape-outline-color',
      handleColorChange: onSetShapeOutlineColor,
      palette: COLORS,
      value: shapeOutlineColor
    };
    const shapeOutlineWidthAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'shape-outline-width',
      onChange: (value) => onSetShapeOutlineWidth(_.round(value, 2)),
      rangeMax: 8,
      rangeMin: 0,
      step: 0.5,
      value: shapeOutlineWidth
    };

    return (
      <AccordionPane key="shapeOutlineColors" title={I18n.t('subheaders.shape_outline', { scope })}>
        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="shape-outline-color">
            {I18n.t('fields.shape_outline_color.title', { scope })}
          </label>
          <ColorPicker {...shapeOutlineColorAttributes} />
        </div>

        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="shape-outline-width">
            {I18n.t('fields.shape_outline_width.title', { scope })}
          </label>
          <div className="debounced-slider-with-preview">
            <div className="slider-container">
              <DebouncedSlider {...shapeOutlineWidthAttributes} />
            </div>
            <LineWeightPreview lineWeight={shapeOutlineWidth} />
          </div>
        </div>
      </AccordionPane>
    );
  }

  renderShowLegendOptions = () => {
    const { onSetShowLegend, vifAuthoring } = this.props;
    const showLegend = selectors.getShowLegend(true)(vifAuthoring);
    const scope = 'shared.visualizations.panes.legends_and_flyouts';
    const inputAttributes = {
      defaultChecked: showLegend,
      onChange: (event) => onSetShowLegend(event.target.checked),
      id: 'show-legends',
      type: 'checkbox'
    };

    return (
      <AccordionPane key="legends" title={I18n.t('subheaders.legends.title', { scope })}>
        <div className="authoring-field checkbox" id="show-legends-container">
          <input {...inputAttributes} />
          <label className="inline-label" htmlFor="show-legends">
            <span className="fake-checkbox">
              <span className="icon-checkmark3"></span>
            </span>
            {I18n.t('fields.show_legends.title', { scope })}
          </label>
        </div>
      </AccordionPane>
    );
  }

  renderColorByQuantificationMethodSelector = () => {
    const {
      onSetColorByQuantificationMethod,
      onSetColorPaletteProperties,
      vifAuthoring
    } = this.props;
    const quantificationMethodAttributes = {
      id: 'quantification-methods',
      onSelection: (event) => {
        onSetColorByQuantificationMethod(event.value);
        onSetColorPaletteProperties();
      },
      options: _.map(QUANTIFICATION_METHODS, method => ({
        title: method.title,
        value: method.value
      })),
      value: selectors.getColorByQuantificationMethod(vifAuthoring)
    };

    return (
      <div className="authoring-field color-by-quantification">
        <label className="block-label" htmlFor="quantification-methods">
          {I18n.t('subheaders.quantification_method', { scope })}
        </label>
        <Dropdown {...quantificationMethodAttributes} />
      </div>
    );
  }

  render() {
    const showDatasetSelectionModal = selectors.getShowDatasetSelectionModal(this.props.vifAuthoring);
    const mapLayersPaneContent = showDatasetSelectionModal ?
      this.renderDatasetSelectorTemplate() :
      this.renderMapLayersPaneContent();

    return (
      <div className="map-layer-pane-content">
        {mapLayersPaneContent}
      </div>
    );
  }
}

MapLayersPane.propTypes = {
  metadata: PropTypes.object,
  metadataCollection: PropTypes.array,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onAddBasemapFlyoutColumn: actions.addBasemapFlyoutColumn,
  onAddRegionMapFlyoutColumnAndAggregation: actions.addRegionMapFlyoutColumnAndAggregation,
  onAppendSeries: actions.appendSeries,
  onChangeAdditionalFlyoutColumn: actions.changeAdditionalFlyoutColumn,
  onChangeRegionMapFlyoutColumnAndAggregation: actions.changeRegionMapFlyoutColumnAndAggregation,
  onRemoveBasemapFlyoutColumn: actions.removeBasemapFlyoutColumn,
  onRemoveMetadataSeries: actions.removeMetadataSeries,
  onRemoveRegionMapFlyoutColumnAndAggregation: actions.removeRegionMapFlyoutColumnAndAggregation,
  onRemoveSeries: actions.removeSeries,
  onSetCastNullAsFalse: actions.setCastNullAsFalseInSeries,
  onSetCharmName: actions.setCharmName,
  onSetColorByBucketsCount: actions.setColorByBucketsCount,
  onSetColorByQuantificationMethod: actions.setColorByQuantificationMethod,
  onSetColorPalette: actions.setColorPalette,
  onSetColorPaletteProperties: actions.setColorPaletteProperties,
  onSetCurrentMapLayerIndex: actions.setCurrentMapLayerIndex,
  onSetCurrentMapLayerMode: actions.setCurrentMapLayerMode,
  onSetDatasetSelectionModalToggle: actions.setDatasetSelectionModalToggle,
  onSetDatasetUid: actions.setDatasetUid,
  onSetDataSource: actions.setDataSource,
  onSetDimension: actions.setDimension,
  onSetDomain: actions.setDomain,
  onSetFilters: actions.setFilters,
  onSetLineColorOpacity: actions.setLineColorOpacity,
  onSetLineWeight: actions.setLineWeight,
  onSetMapFlyoutTitleColumnName: actions.setMapFlyoutTitleColumnName,
  onSetMapLayerName: actions.setMapLayerName,
  onSetMapLayerVisible: actions.setMapLayerVisible,
  onSetMapType: actions.setMapType,
  onSetMaximumLineWeight: actions.setMaximumLineWeight,
  onSetMaximumPointSize: actions.setMaximumPointSize,
  onSetMidpoint: actions.setMidpoint,
  onSetMinimumLineWeight: actions.setMinimumLineWeight,
  onSetMinimumPointSize: actions.setMinimumPointSize,
  onSetNumberOfDataClasses: actions.setNumberOfDataClasses,
  onSetPointOpacity: actions.setPointOpacity,
  onSetPointSize: actions.setPointSize,
  onSetPrimaryColor: actions.setPrimaryColor,
  onSetRangeBucketType: actions.setRangeBucketType,
  onSetShapeFillColor: actions.setShapeFillColor,
  onSetShapeFillOpacity: actions.setShapeFillOpacity,
  onSetShapeOutlineColor: actions.setShapeOutlineColor,
  onSetShapeOutlineWidth: actions.setShapeOutlineWidth,
  onSetShowLegend: actions.setShowLegend,
  onSetSimplificationLevel: actions.setSimplificationLevel,
  onSetTriggerAutoSelectGeoLocationColumn: actions.setTriggerAutoSelectGeoLocationColumn,
  onSwapMetaDataCollectionSeries: actions.swapMetaDataCollectionSeries,
  onSwapSeries: actions.swapSeries,
  onSwapColorPalette: actions.swapColorPaletteWithQuantification,
  onUpdateCustomCharmName: actions.updateCustomCharmNameWithQuantification,
  onUpdateCustomColorPalette: actions.updateCustomColorPaletteWithQuantification
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  metadataCollection: state.metadataCollection,
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(MapLayersPane);
