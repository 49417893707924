// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setColorByQuantificationMethod,
  setColorPointsByColumn,
  setResizePointsByColumn
} from '../../../actions';
import {
  getCurrentMetadata,
  getNonGeoLocationColumns,
  getNumericalColumns
} from '../../../selectors/metadata';
import {
  getColorByQuantificationMethod,
  getColorPointsByColumn,
  getMapType,
  getPointAggregation,
  getResizePointsByColumn
} from '../../../selectors/vifAuthoring';
import I18n from 'common/i18n';
import { getIconClassForDataType } from 'common/views/dataTypeMetadata';
import AutocompleteColumnSelector from 'common/authoring_workflow/components/shared/AutocompleteColumnSelector';
import { normalizeColorByQuantificationMethod } from 'common/authoring_workflow/helpers';

// Constants
import { NULL_COLUMN_NAME } from '../../../constants';

export class PointMapOptionsSelector extends Component {
  renderPointMapColumnOption(option) {
    const iconClassForDataType = getIconClassForDataType(option.type);

    return (
      <div className="dataset-column-selector-option">
        <span className={iconClassForDataType}></span> {option.title}
      </div>
    );
  }

  renderPointMapOptionsDropdown(dropdownId, optionsData, onSelection, defaultValue) {
    const { vifAuthoring, translationScope, onSetColorByQuantificationMethod } = this.props;
    const columnOptions = _.map(optionsData, column => ({
      title: column.name,
      value: column.fieldName,
      type: column.renderTypeName,
      render: this.renderPointMapColumnOption
    }));
    const options = [
      {
        title: I18n.t('no_value', { scope: translationScope }),
        value: NULL_COLUMN_NAME
      },
      ...columnOptions
    ];
    const hasPointAggregation = _.includes(['heat_map', 'region_map'], getPointAggregation(vifAuthoring));
    const disabled = (columnOptions.length === 0) || hasPointAggregation;

    return {
      disabled,
      id: dropdownId,
      placeholder: I18n.t('no_value', { scope: translationScope }),
      options,
      onSelection: (option, index) => {
        const quantificationMethod = normalizeColorByQuantificationMethod(
          getColorPointsByColumn(vifAuthoring),
          getColorByQuantificationMethod(vifAuthoring),
          option.type
        );

        onSetColorByQuantificationMethod(quantificationMethod);

        if (option.value === NULL_COLUMN_NAME) {
            option.value = null;
        }
        onSelection(option, index);
      },
      // Forge Autocomplete does not call onSelect if option.value = null.
      // We pass it as a string so that the user can select the option.
      value: defaultValue === null ? NULL_COLUMN_NAME : defaultValue
    };
  }

  renderPointSizeSelector() {
    const {
      metadata,
      onSetResizePointsByColumn,
      translationScope,
      vifAuthoring
    } = this.props;

    const numericalColumnAttributes = this.renderPointMapOptionsDropdown(
      'resize-points-by-value-dropdown',
      getNumericalColumns(metadata),
      (option) => onSetResizePointsByColumn(option.value),
      getResizePointsByColumn(vifAuthoring)
    );

    return (
      <div className="authoring-field" id="resize-points-by-value-selection">
        <label
          className="block-label"
          htmlFor="base-layer">{I18n.t('resize_points_by_value', { scope: translationScope })}</label>
        <div className="base-layer-dropdown-container">
          <AutocompleteColumnSelector {...numericalColumnAttributes} />
        </div>
      </div>
    );
  }

  renderPointColorSelector() {
    const {
      metadata,
      onSetColorPointsByColumn,
      translationScope,
      vifAuthoring
    } = this.props;

    const allColumnAttributes = this.renderPointMapOptionsDropdown(
      'color-by-value-dropdown',
      getNonGeoLocationColumns(metadata),
      (option) => {
        onSetColorPointsByColumn(option.value);
      },
      getColorPointsByColumn(vifAuthoring)
    );

    return (
      <div className="authoring-field" id="color-points-by-value-selection">
        <label
          className="block-label"
          htmlFor="base-layer">{I18n.t('color_points_by_value', { scope: translationScope })}</label>
        <div className="base-layer-dropdown-container">
          <AutocompleteColumnSelector {...allColumnAttributes} />
        </div>
      </div>
    );
  }

  render() {
    const isPointMap = _.isEqual(getMapType(this.props.vifAuthoring), 'pointMap');

    return isPointMap ? (
      <div>
        {this.renderPointSizeSelector()}
        {this.renderPointColorSelector()}
      </div>
    ) : null;
  }
}

PointMapOptionsSelector.defaultProps = {
  translationScope: 'shared.visualizations.panes.map_layers.fields.point_map_options'
};

PointMapOptionsSelector.propTypes = {
  metadata: PropTypes.object,
  onSetColorByQuantificationMethod: PropTypes.func,
  onSetColorPointsByColumn: PropTypes.func,
  onSetResizePointsByColumn: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetColorByQuantificationMethod: setColorByQuantificationMethod,
  onSetColorPointsByColumn: setColorPointsByColumn,
  onSetResizePointsByColumn: setResizePointsByColumn
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(PointMapOptionsSelector);
