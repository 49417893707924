import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { components as SocrataVisualizations } from 'common/visualizations';
import EditVisualizationButton from './EditVisualizationButton';
import ShareVisualizationButton from './ShareVisualizationButton';

export class VisualizationWrapper extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'onMapCenterAndZoomChange',
      'onMapNotificationDismiss',
      'onMapPitchAndBearingChange',
      'onToggleMapLayerVisible'
    ]);
  }

  componentDidMount() {
    $(this.visualization)
      .on('SOCRATA_VISUALIZATION_MAP_CENTER_AND_ZOOM_CHANGED', this.onMapCenterAndZoomChange)
      .on('SOCRATA_VISUALIZATION_PITCH_AND_BEARING_CHANGED', this.onMapPitchAndBearingChange)
      .on('SOCRATA_VISUALIZATION_FILTERS_CHANGED', this.onFiltersChange)
      .on('SOCRATA_VISUALIZATION_DRILL_DOWN_CHANGED', this.onDrilldownColumnChange)
      .on('SOCRATA_VISUALIZATION_CURRENT_DATE_CHANGED', this.onCurrentDisplayDateChange)
      .on('SOCRATA_VISUALIZATION_TOGGLE_MAP_LAYER', this.onToggleMapLayerVisible);
  }

  componentWillUnmount() {
    $(this.visualization)
      .off('SOCRATA_VISUALIZATION_MAP_CENTER_AND_ZOOM_CHANGED', this.onMapCenterAndZoomChange)
      .off('SOCRATA_VISUALIZATION_PITCH_AND_BEARING_CHANGED', this.onMapPitchAndBearingChange)
      .off('SOCRATA_VISUALIZATION_FILTERS_CHANGED', this.onFiltersChange)
      .off('SOCRATA_VISUALIZATION_DRILL_DOWN_CHANGED', this.onDrilldownColumnChange)
      .off('SOCRATA_VISUALIZATION_CURRENT_DATE_CHANGED', this.onCurrentDisplayDateChange)
      .off('SOCRATA_VISUALIZATION_TOGGLE_MAP_LAYER', this.onToggleMapLayerVisible);
  }

  onMapCenterAndZoomChange(event) {
    const { isEditable, vif, vifIndex, onMapCenterAndZoomChange } = this.props;
    const centerAndZoom = event.originalEvent.detail;
    const vifCenterAndZoom = _.get(vif, 'configuration.mapCenterAndZoom');

    // only update the VIF if the visualization is editable and the center/zoom has changed
    if (isEditable && !_.isEqual(vifCenterAndZoom, centerAndZoom)) {
      onMapCenterAndZoomChange({
        centerAndZoom,
        vifIndex
      });
    }
  }

  onToggleMapLayerVisible(event) {
    const { vif, vifIndex, onToggleMapLayerVisible } = this.props;
    const { relativeIndex, visible } = event.originalEvent.detail;
    const vifToggleMapLayer = _.get(vif, `series[${relativeIndex}].visible`);

    // map layer visible has changed
    if (!_.isEqual(vifToggleMapLayer, visible)) {
      onToggleMapLayerVisible({
        visible,
        vifIndex,
        relativeIndex
      });
    }
  }

  onMapPitchAndBearingChange(event) {
    const { isEditable, vif, vifIndex, onMapPitchAndBearingChange } = this.props;
    const pitchAndBearing = event.originalEvent.detail;
    const vifPitchAndBearing = _.get(vif, 'configuration.mapPitchAndBearing');

    // only update the VIF if the visualization is editable and the pitch/bearing has changed
    if (isEditable && !_.isEqual(vifPitchAndBearing, pitchAndBearing)) {
      onMapPitchAndBearingChange({
        pitchAndBearing,
        vifIndex
      });
    }
  }

  onFiltersChange = (event) => {
    const { onSetFilters } = this.props;
    const { filters } = _.get(event, 'originalEvent.detail');

    onSetFilters(filters);
  }

  onDrilldownColumnChange = (event) => {
    const { onSetCurrentDrilldownColumnName } = this.props;
    const { currentDrilldownDimensionColumnName } = _.get(event, 'originalEvent.detail');

    onSetCurrentDrilldownColumnName(currentDrilldownDimensionColumnName);
  }

  onCurrentDisplayDateChange = (event) => {
    const newDateString = _.get(event, 'originalEvent.detail');
    this.props.onSetCurrentDisplayDate(newDateString);
  }

  onMapNotificationDismiss() {
    const { vifIndex, onMapNotificationDismiss } = this.props;

    onMapNotificationDismiss({ vifIndex });
  }

  render() {
    const { isEditable, isPending, vifIndex, vif } = this.props;
    const options = {
      drilldown: {
        emitDrilldownChange: true,
        handleVifUpdatesInternally: !isEditable,
      }
    };

    if (_.isEmpty(vif)) {
      return null;
    }

    const editButton = !isPending && isEditable &&
      <EditVisualizationButton vifIndex={vifIndex} />;

    const shareButton = <ShareVisualizationButton vifIndex={vifIndex} />;
    const isCalendar = _.get(vif, 'series[0].type') === 'calendar';
    const actionBarClassNames = classNames('action-btns', { 'calendar-container': isCalendar });

    return (
      <div className="visualization-wrapper" ref={(ref) => this.visualization = ref}>
        <SocrataVisualizations.Visualization vif={vif} options={options} />
        {/* This is rendered after the visualization for a better tab order. */}
        <div className={actionBarClassNames}>
          {editButton}
          {shareButton}
        </div>
      </div>
    );
  }
}

VisualizationWrapper.propTypes = {
  vif: PropTypes.object.isRequired,
  vifIndex: PropTypes.number.isRequired,
  isEditable: PropTypes.bool,
  isPending: PropTypes.bool,
  mapNotificationDismissed: PropTypes.bool,
  onSetCurrentDrilldownColumnName: PropTypes.func,
  onCurrentDisplayDateChange: PropTypes.func,
  onSetFilters: PropTypes.func,
  onMapCenterAndZoomChange: PropTypes.func,
  onMapNotificationDismiss: PropTypes.func,
  onMapPitchAndBearingChange: PropTypes.func,
  onToggleMapLayerVisible: PropTypes.func
};

VisualizationWrapper.defaultProps = {
  isEditable: false,
  isPending: false,
  mapNotificationDismissed: false,
  onSetCurrentDrilldownColumnName: _.noop,
  onCurrentDisplayDateChange: _.noop,
  onSetFilters: _.noop,
  onMapCenterAndZoomChange: _.noop,
  onMapNotificationDismiss: _.noop,
  onMapPitchAndBearingChange: _.noop,
  onToggleMapLayerVisible: _.noop
};

export default VisualizationWrapper;
