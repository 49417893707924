import React, { useEffect, useMemo, useState } from 'react';

import DisplayOptions from './DisplayOptions';
import {
  AuthoringWorkflowState,
  ConditionalConfig,
  Expression,
  FormatStyle,
  TableColumnFormat,
  VifAuthoring
} from 'common/authoring_workflow/reducers/types';
import { FILTER_FUNCTION } from 'common/components/FilterBar/SoqlFilter';
import {
  getSelectedColumnStat,
  getDefaultColumnFormat,
  isColumnFormatEqual
} from 'common/visualizations/helpers/AgGridHelpers';
import { find, get } from 'lodash';

import { connect } from 'react-redux';
import {
  updateTableVisualizationColumnFormat,
  updateTableVisualizationColumnStyle
} from 'common/authoring_workflow/actions';
import {
  getColumnFormats,
  getCurrentVif,
  getTableColumns
} from 'common/authoring_workflow/selectors/vifAuthoring.js';
import { DropdownOption } from 'common/authoring_workflow/components/shared/AutocompleteColumnSelector';

import './index.scss';
import StyleOptions from './StyleOptions';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import ConditionalOptions from './ConditionalOptions';
import AgColumnNameSelector from '../AgGridComponents/AgColumnNameSelector';
import { defaultConditionStyle, defaultConditionalConfig } from './helpers';
import { isComplexConditionalFormattingEnabled } from 'common/visualizations/helpers/VifSelectors';

interface IExpressionParams {
  function: string;
  arguments: {
    start?: string;
    end?: string;
  };
  precision?: number;
}

const computeDefaultExpressionParams = async (
  vifAuthoring: VifAuthoring,
  columnName: string
): Promise<IExpressionParams> => {
  const selectedColumns = getTableColumns(vifAuthoring);
  const vif = getCurrentVif(vifAuthoring);
  const { isDateColumn, metadata } = await getSelectedColumnStat(vif, selectedColumns, columnName);

  return {
    function: isDateColumn ? FILTER_FUNCTION.TIME_RANGE : FILTER_FUNCTION.EQUALS,
    arguments: {
      start: undefined,
      end: undefined
    },
    precision: metadata?.format?.precision
  };
};

const computeDefaultColumnFormat = (
  vifAuthoring: VifAuthoring,
  columnName: string,
  expressionParams?: IExpressionParams
): TableColumnFormat => {
  const selectedColumns = getTableColumns(vifAuthoring);
  const matchedColumnDetails = find(selectedColumns, { fieldName: columnName });
  const format = getDefaultColumnFormat(matchedColumnDetails);
  const updatedExpression = {
    function: expressionParams?.function ?? FILTER_FUNCTION.EQUALS,
    arguments: {
      start: expressionParams?.arguments.start ?? undefined,
      end: expressionParams?.arguments.end ?? undefined,
      value: ''
    }
  } as Expression;

  return {
    columnName,
    displayName: '',
    conditionalType: 'condition',
    colorPalette: 'accent',
    colorPaletteType: 'default',
    format: {
      ...format,
      precision: expressionParams?.precision ?? undefined
    },
    conditionStyle: defaultConditionStyle(),
    style: {
      backgroundColor: '#ffffff',
      textColor: '#000000',
      textFont: '',
      fontStyle: {
        isBold: false,
        isItalic: false,
        isWrapped: false
      }
    },
    expression: updatedExpression,
    isFormatValue: true,
    isAlignHeader: false,
    isFormatTotal: false,
    isFormatSubtotal: false,
    conditionalConfig: []
  };
};

const computeDefaultConditionalConfig = async (vifAuthoring: VifAuthoring, columnName: string) => {
  const selectedColumns = getTableColumns(vifAuthoring);
  const vif = getCurrentVif(vifAuthoring);
  const { isDateColumn } = await getSelectedColumnStat(vif, selectedColumns, columnName);
  return isComplexConditionalFormattingEnabled() ? [] : [defaultConditionalConfig(isDateColumn)];
};

export interface AgGridUpdatedColumnFormattingProps {
  vifAuthoring: VifAuthoring;
  onUpdateColumnFormat: (format: TableColumnFormat, columnName: string) => void;
  onUpdateColumnFormatStyle: (style: FormatStyle, columnName: string) => void;
}

const AgGridUpdatedColumnFormatting = (props: AgGridUpdatedColumnFormattingProps) => {
  const { vifAuthoring, onUpdateColumnFormat, onUpdateColumnFormatStyle } = props;
  const [columnName, setColumnName] = useState<string>('');
  const [expressionParams, setExpressionParams] = useState<IExpressionParams>();
  const [defaultConditionalConfigArray, setDefaultConditionalConfigArray] = useState<ConditionalConfig[]>([]);
  const defaultColumnFormat: TableColumnFormat = useMemo(() => {
    return {
      ...computeDefaultColumnFormat(vifAuthoring, columnName, expressionParams),
      conditionalConfig: defaultConditionalConfigArray
    };
  }, [columnName, defaultConditionalConfigArray, expressionParams, vifAuthoring]);

  const columnFormats: { [key: string]: TableColumnFormat } = getColumnFormats(vifAuthoring);
  const columnFormat = { ...defaultColumnFormat, ...columnFormats[columnName] };
  const selectedColumns = getTableColumns(vifAuthoring);
  const currentColumn = find(selectedColumns, { fieldName: columnName });
  const displayName = get(columnFormat, 'displayName', '');

  useEffect(() => {
    computeDefaultConditionalConfig(vifAuthoring, columnName).then((conditionalConfig) => {
      setDefaultConditionalConfigArray(conditionalConfig);
    });
  }, [columnName, vifAuthoring]);

  const onColumnNameChange = async (newColumnName: string) => {
    const newExpressionParams = await computeDefaultExpressionParams(vifAuthoring, newColumnName);
    setExpressionParams(newExpressionParams);
    setColumnName(newColumnName);
  };

  const handleColumnNameChange = (dimensionOption: DropdownOption) => {
    const updatedColumnName = get(dimensionOption, 'value');
    onColumnNameChange(updatedColumnName);
  };

  const handleUpdateColumnFormatStyle = (updatedColumnFormatStyle: FormatStyle, updatedColumn: string) => {
    onUpdateColumnFormatStyle(updatedColumnFormatStyle, updatedColumn);
  };

  const handleResetColumnFormat = () => {
    const updatedColumnFormat = { ...defaultColumnFormat, displayName };
    onUpdateColumnFormat(updatedColumnFormat, columnName);
  };

  const renderResetButton = () => {
    const scope = 'shared.visualizations.panes.presentation';
    const resetButtonLabel = I18n.t('fields.column_format.reset_to_default', { scope });

    return (
      <ForgeButton className="ag-grid-reset-format-button">
        <button
          aria-label={resetButtonLabel}
          onClick={handleResetColumnFormat}
          disabled={isColumnFormatEqual(columnFormat, defaultColumnFormat)}
        >
          <ForgeIcon name="refresh" />
          <span>{resetButtonLabel}</span>
        </button>
      </ForgeButton>
    );
  };

  return (
    <div className="ag-grid-column-format-option">
      <div className="ag-grid-column-format-container">
        <AgColumnNameSelector
          columnName={columnName}
          vifAuthoring={vifAuthoring}
          onColumnNameChange={handleColumnNameChange}
        />
        {columnName && (
          <>
            <DisplayOptions
              tableColumnFormat={columnFormat}
              onUpdateColumnFormat={onUpdateColumnFormat}
              viewColumn={currentColumn}
            />
            <StyleOptions
              tableColumnFormat={columnFormat}
              onUpdateColumnFormat={onUpdateColumnFormat}
              viewColumn={currentColumn}
              onUpdateColumnFormatStyle={handleUpdateColumnFormatStyle}
            />
            <ConditionalOptions
              tableColumnFormat={columnFormat}
              viewColumn={currentColumn}
              onUpdateColumnFormat={onUpdateColumnFormat}
            />
            {renderResetButton()}
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  onUpdateColumnFormat: updateTableVisualizationColumnFormat,
  onUpdateColumnFormatStyle: updateTableVisualizationColumnStyle
};

const mapStateToProps = (state: AuthoringWorkflowState) => ({
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(AgGridUpdatedColumnFormatting);
