// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setMaximumPlotSize,
  setMinimumPlotSize,
  setNumberOfDataClasses,
  setPlotSize
} from '../../../actions';
import * as selectors from '../../../selectors/vifAuthoring';
import DebouncedSlider from '../../shared/DebouncedSlider';
import I18n from 'common/i18n';
import { Dropdown } from 'common/components/Dropdown';
import { getMapSliderDebounceMs, SCATTER_CHART, VIF_CONSTANTS } from '../../../constants';
import PointSizePreview from '../../shared/PointSizePreview';

// Constants
const scope = 'shared.visualizations.panes.presentation';

export class ScatterChartPlotSizeSelector extends Component {
  renderDataClassesSelector = () => {
    const { onSetNumberOfDataClasses, vifAuthoring } = this.props;
    const numberOfDataClasses = selectors.getScatterChartNumberOfDataClasses(vifAuthoring);
    const options = _.map(
       _.range(VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.MIN, VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.MAX + 1),
       i => ({ title: i.toString(), value: i.toString() })
    );
    const dataClassesAttributes = {
      disabled: false,
      id: 'data-classes-scatter-chart',
      onSelection: (option) => onSetNumberOfDataClasses(option.value),
      options: options,
      value: numberOfDataClasses.toString()
    };

    return (
      <div className="authoring-field">
        <label className="block-label" htmlFor="data-classes">
          {I18n.t('fields.data_classes.title', { scope })}
        </label>
        <div className="data-classes-dropdown-container">
          <Dropdown {...dataClassesAttributes} />
        </div>
      </div>
    );
  }

  renderMinAndMaxPlotSizeSelectors = () => {
    const { onSetMaximumPlotSize, onSetMinimumPlotSize, vifAuthoring } = this.props;
    const minimumPlotSize = selectors.getMinimumPlotSize(vifAuthoring);
    const maximumPlotSize = selectors.getMaximumPlotSize(vifAuthoring);
    const minimumPlotSizeAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'minimum-plot-size',
      onChange: (minimumPlotSize) => onSetMinimumPlotSize(_.round(minimumPlotSize, 2)),
      rangeMax: SCATTER_CHART.MIN_PLOT_SIZE.MAX,
      rangeMin: SCATTER_CHART.MIN_PLOT_SIZE.MIN,
      step: SCATTER_CHART.MIN_PLOT_SIZE.STEP,
      value: minimumPlotSize
    };
    const maximumPlotSizeAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'maximum-plot-size',
      onChange: (maximumPlotSize) => onSetMaximumPlotSize(_.round(maximumPlotSize, 2)),
      rangeMax: SCATTER_CHART.MAX_PLOT_SIZE.MAX,
      rangeMin: SCATTER_CHART.MAX_PLOT_SIZE.MIN,
      step: SCATTER_CHART.MIN_PLOT_SIZE.STEP,
      value: maximumPlotSize
    };

    return (
      <div className="plot-size-min-max-selection-container">
        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="minimum-plot-size">
            {I18n.t('fields.point_size.minimum', { scope })}
          </label>
          <div className="debounced-slider-with-preview">
            <div className="slider-container">
              <DebouncedSlider {...minimumPlotSizeAttributes} />
            </div>
            <PointSizePreview pointSize={minimumPlotSize} />
          </div>
        </div>

        <div className="authoring-field">
          <label
            className="block-label"
            htmlFor="maximum-plot-size">
            {I18n.t('fields.point_size.maximum', { scope })}
          </label>
          <div className="debounced-slider-with-preview">
            <div className="slider-container max-plot-size">
              <DebouncedSlider {...maximumPlotSizeAttributes} />
            </div>
            <PointSizePreview pointSize={maximumPlotSize} />
          </div>
        </div>

        {this.renderDataClassesSelector()}
      </div>
    );
  }

  renderPlotSizeSelector = () => {
    const { onSetPlotSize, vifAuthoring } = this.props;
    const plotSize = selectors.getPlotSize(vifAuthoring);
    const plotSizeAttributes = {
      delay: getMapSliderDebounceMs(),
      id: 'plot-size',
      onChange: (plotSize) => onSetPlotSize(_.round(plotSize, 2)),
      rangeMax: SCATTER_CHART.PLOT_SIZE.MAX,
      rangeMin: SCATTER_CHART.PLOT_SIZE.MIN,
      step: SCATTER_CHART.PLOT_SIZE.STEP,
      value: plotSize
    };

    return (
      <div className="authoring-field">
        <label
          className="block-label"
          htmlFor="plot-size">
          {I18n.t('fields.point_size.title', { scope })}
        </label>
        <div id="plot-size-slider-container">
          <DebouncedSlider {...plotSizeAttributes} />
        </div>
      </div>
    );
  }

  render() {
    const { vifAuthoring } = this.props;
    const isResizeByColumnSelected = selectors.getResizeByColumn(vifAuthoring);

    if (isResizeByColumnSelected) {
      return this.renderMinAndMaxPlotSizeSelectors();
    } else {
      return this.renderPlotSizeSelector();
    }
  }
}

ScatterChartPlotSizeSelector.propTypes = {
  onSetMaximumPlotSize: PropTypes.func,
  onSetMinimumPlotSize: PropTypes.func,
  onSetNumberOfDataClasses: PropTypes.func,
  onSetPlotSize: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetMaximumPlotSize: setMaximumPlotSize,
  onSetMinimumPlotSize: setMinimumPlotSize,
  onSetNumberOfDataClasses: setNumberOfDataClasses,
  onSetPlotSize: setPlotSize
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(ScatterChartPlotSizeSelector);
