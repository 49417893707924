// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setDescription,
  setTitle,
  setViewSourceDataLink
} from '../../../actions';
import * as selectors from '../../../selectors/vifAuthoring';
import DebouncedInput from '../../shared/DebouncedInput';
import DebouncedTextArea from '../../shared/DebouncedTextArea';
import I18n from 'common/i18n';
import { FeatureFlags } from 'common/feature_flags';

const scope = 'shared.visualizations.panes.presentation';

export class TitleDescriptionSelector extends Component {

  renderTitleField = () => {
    const { onSetTitle, vifAuthoring } = this.props;
    const inputAttributes = {
      className: 'text-input',
      id: 'title',
      onChange: (event) => onSetTitle(event.target.value),
      type: 'text',
      value: selectors.getTitle(vifAuthoring)
    };

    return (
      <div className="authoring-field">
        <label className="block-label" htmlFor="title">{I18n.t('fields.title.title', { scope })}</label>
        <DebouncedInput {...inputAttributes} />
      </div>
    );
  }

  renderDescriptionField = () => {
    const { onSetDescription, vifAuthoring } = this.props;

    const textAreaAttributes = {
      className: 'text-input text-area',
      id: 'description',
      onChange: (event) => onSetDescription(event.target.value),
      value: selectors.getDescription(vifAuthoring)
    };

    return (
      <div className="authoring-field">
        <label className="block-label" htmlFor="description">
          {I18n.t('fields.description.title', { scope })}
        </label>
        <DebouncedTextArea {...textAreaAttributes} />
      </div>
    );
  }

  renderShowSourceDataLink = () => {
    const { onSetViewSourceDataLink, vifAuthoring } = this.props;
    const inputAttributes = {
      checked: selectors.getViewSourceDataLink(vifAuthoring),
      id: 'show-source-data-link',
      onChange: (event) => onSetViewSourceDataLink(event.target.checked),
      type: 'checkbox'
    };

    return (
      <div className="authoring-field checkbox">
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor="show-source-data-link">
          <span className="fake-checkbox">
            <span className="icon-checkmark3"></span>
          </span>
          {I18n.t('fields.show_source_data_link.title', { scope })}
        </label>
      </div>
    );
  }

  render() {
    const viewSourceDataEnabled = FeatureFlags.value('enable_view_source_data_stories');
    return (
      <div>
        {this.renderTitleField()}
        {this.renderDescriptionField()}
        {viewSourceDataEnabled && this.renderShowSourceDataLink()}
      </div>
    );
  }
}

TitleDescriptionSelector.propTypes = {
  onSetDescription: PropTypes.func,
  onSetTitle: PropTypes.func,
  onSetViewSourceDataLink: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetDescription: setDescription,
  onSetTitle: setTitle,
  onSetViewSourceDataLink: setViewSourceDataLink
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(TitleDescriptionSelector);
