// Vendor Imports
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import { getTodayDate } from 'common/dates';

import FlyoutFactory from 'common/components/legacy/Flyout';
import Button, { SIZES } from 'common/components/Button';
import { getDefaultFilterForColumn } from 'common/components/SingleSourceFilterBar/filters';
import { getNoopFilter } from 'common/components/FilterBar/lib/Filters/BaseFilter';

// Project Imports
import {
  setColorPaletteProperties,
  setCurrentMapLayerIndex,
  setDataSource,
  setShowDataTableControl,
  setDefaultDisplayDate,
  setDimension,
  setFilters,
  setMapType,
  setOrderBy,
  setShowMultiplePointsSymbolInLegend,
  setStartDateColumn,
  setVisualizationType,
  setXAxisColumn,
  setYAxisColumn
} from '../actions';
import {
  CALENDAR,
  DEFAULT_SCATTER_CHART_COLUMN_VALUE,
  SCATTER_CHART,
  VIF_CONSTANTS,
  VISUALIZATION_TYPES
} from '../constants';
import { SERIES_TYPE_AG_GRID_TABLE } from 'common/visualizations/views/SvgConstants';
import {
  getAnyLocationColumn,
  getCurrentMetadata,
  getFirstOccurringGeoLocationColumn,
  getMapType,
  getRecommendedDimensions,
  getRecommendedVisualizationTypes,
  getValidDimensions,
  getValidMeasures,
  hasData,
  hasRegions,
  isGeoLocationColumn
} from '../selectors/metadata';
import {
  getAnyDimension,
  getChartFilters,
  getDrilldowns,
  getCurrentSeriesIndex,
  getPrimaryMapLayerSeries,
  getPrimaryMapLayerSeriesIndex,
  getSelectedVisualizationType,
  hasVisualizationDimension,
  isRegionMap
} from '../selectors/vifAuthoring';
import { isInsituViz } from '../helpers';

export class VisualizationTypeSelector extends Component {
  componentDidUpdate() {
    const { selector } = this;

    if (selector) {
      new FlyoutFactory(selector);
    }
  }

  onClickVisualizationType = (visualizationType) => {
    return () => {
      const {
        metadata,
        onChangeVisualizationType,
        onSetColorPaletteProperties,
        onSetCurrentMapLayerIndex,
        onSetDataSource,
        onSetShowDataTableControl,
        onSetDefaultDisplayDate,
        onSetDimension,
        onSetShowMultiplePointsSymbolInLegend,
        onSetStartDateColumn,
        onSetFilters,
        onSetMapType,
        onSetVisualizationType,
        onSetXAxisColumn,
        onSetYAxisColumn,
        vifAuthoring
      } = this.props;
      onSetVisualizationType(visualizationType);

      // Set a dimension if not already set
      if (!hasVisualizationDimension(vifAuthoring)) {
        this.setDefaultDimension(visualizationType);
      }

      // Set color palette only for map visualizations
      if (visualizationType === 'map') {
        onSetColorPaletteProperties();
      }

      if (visualizationType === 'scatterChart') {
        const scatterChartSeries = _.get(vifAuthoring.vifs, 'scatterChart.series');
        const xAxisColumn = _.get(
          scatterChartSeries[SCATTER_CHART.X_AXIS_SERIES_INDEX],
          'dataSource.measure.columnName'
        );
        const yAxisColumn = _.get(
          scatterChartSeries[SCATTER_CHART.Y_AXIS_SERIES_INDEX],
          'dataSource.measure.columnName'
        );
        const measures = getValidMeasures(metadata);

        if (_.isNil(xAxisColumn)) {
          onSetXAxisColumn(_.get(measures[0], 'fieldName', DEFAULT_SCATTER_CHART_COLUMN_VALUE));
        }

        if (_.isNil(yAxisColumn)) {
          onSetYAxisColumn(_.get(measures[1], 'fieldName', DEFAULT_SCATTER_CHART_COLUMN_VALUE));
        }
      }

      if (visualizationType === 'calendar') {
        const calendarSeries = _.get(vifAuthoring.vifs, 'calendar.series');
        const startDateColumn = _.get(
          calendarSeries[CALENDAR.START_DATE_SERIES_INDEX],
          'dataSource.dimension.columnName'
        );
        const defaultDisplayDate = _.get(vifAuthoring.vifs, 'calendar.configuration.defaultDisplayDate');
        const dimensions = getRecommendedDimensions(metadata, visualizationType);

        if (_.isEmpty(startDateColumn)) {
          onSetStartDateColumn(
            _.get(dimensions[0], 'fieldName', VIF_CONSTANTS.DEFAULT_START_DATE_COLUMN_VALUE)
          );
        }

        if (_.isNil(defaultDisplayDate)) {
          onSetDefaultDisplayDate(getTodayDate());
        }
      }
      const prevType = _.get(vifAuthoring, 'authoring.selectedVisualizationType');
      if (visualizationType != 'agTable' && prevType === 'agTable') {
        onSetShowDataTableControl(true);
      }

      if (visualizationType !== 'map' && prevType === 'map') {
        const currentVizColumnName = _.get(
          vifAuthoring,
          `vifs.${visualizationType}.series[0].dataSource.dimension.columnName`
        );
        const primaryLayerSeries = getPrimaryMapLayerSeries(vifAuthoring);
        const primaryLayerColName = _.get(primaryLayerSeries, 'dataSource.dimension.columnName');
        if (primaryLayerColName !== currentVizColumnName) {
          onSetDimension(primaryLayerColName);
        }
      }

      const mapVisualizationTypes = ['featureMap', 'map', 'regionMap'];
      const isMap = _.includes(mapVisualizationTypes, visualizationType);
      const isDrilldownChartType = _.includes(['barChart', 'columnChart', 'pieChart'], visualizationType);
      const newVifAuthoring = _.cloneDeep(vifAuthoring);
      _.set(newVifAuthoring, 'authoring.selectedVisualizationType', visualizationType);
      const drilldownDimensions = _.map(getDrilldowns(newVifAuthoring), 'columnName');
      const dimensionColumnName = _.get(getAnyDimension(vifAuthoring), 'columnName');
      const filters = getChartFilters(vifAuthoring);
      const drilldownFilters = _.filter(filters, { isDrilldown: true });

      if (isDrilldownChartType && !_.isEmpty(drilldownDimensions)) {
        const dimensionAndDrilldowns = [dimensionColumnName, ...drilldownDimensions];
        const normalFilters = _.filter(filters, (filterItem) => {
          const columnName = filterItem.columns[0].fieldName;
          return !_.includes(dimensionAndDrilldowns, columnName);
        });
        const drilldownFilters = _.map(dimensionAndDrilldowns, (drilldownColumn) => {
          const columnLookup = ['columns[0].fieldname', drilldownColumn];
          const drilldownColumnFilter = _.find(filters, columnLookup);
          const noopFilter = getNoopFilter({ [metadata.datasetUid]: _.find(metadata.data.columns, ['fieldName', drilldownColumn]) }, true);
          return _.isUndefined(drilldownColumnFilter)
            ? noopFilter
            : _.merge({}, drilldownColumnFilter, { isDrilldown: true });
        });

        onSetFilters([...drilldownFilters, ...normalFilters]);
      } else if (!_.isEmpty(drilldownFilters)) {
        const newFilters = _.map(filters, (filterItem) => _.merge({}, filterItem, { isDrilldown: false }));

        onSetFilters(newFilters);
      }

      if (isMap) {
        const areNewMapsEnabled = FeatureFlags.value('enable_new_maps');
        const dimension = getAnyDimension(vifAuthoring);
        const showMultiplePointsSymbolInLegend = _.get(
          vifAuthoring.vifs,
          'map.configuration.showMultiplePointsSymbolInLegend'
        );
        if (_.isNil(showMultiplePointsSymbolInLegend)) {
          onSetShowMultiplePointsSymbolInLegend(true);
        }

        if (_.isNull(dimension.columnName) || !isGeoLocationColumn(metadata, dimension)) {
          if (areNewMapsEnabled) {
            const columnName = _.get(getFirstOccurringGeoLocationColumn(metadata), 'fieldName', null);

            onSetDimension(columnName);
            onSetMapType(getMapType(metadata, { columnName }));
          } else {
            onSetDimension(_.get(getAnyLocationColumn(metadata), 'fieldName', null));
          }
        } else if (areNewMapsEnabled) {
          const columnName = dimension.columnName;
          onSetMapType(getMapType(metadata, { columnName }));
        }
      }

      setTimeout(() => {
        onChangeVisualizationType();

        // Switch to primary dataset when switching from map layers
        const currentSeriesIndex = getCurrentSeriesIndex(vifAuthoring);
        const primarySeriesIndex = getPrimaryMapLayerSeriesIndex(vifAuthoring);

        if (
          visualizationType !== 'map' &&
          primarySeriesIndex !== -1 &&
          currentSeriesIndex !== primarySeriesIndex
        ) {
          const primarySeries = getPrimaryMapLayerSeries(vifAuthoring);
          const { domain, datasetUid, filters } = primarySeries.dataSource;

          onSetCurrentMapLayerIndex(primarySeriesIndex);
          onSetDataSource(domain, datasetUid, filters, true, currentSeriesIndex);
        }
      });
    };
  };

  setDefaultDimension = (visualizationType) => {
    const { onSetDimension, onSetOrderBy } = this.props;
    let dimension;

    if (_.includes(['barChart', 'columnChart', 'comboChart'], visualizationType)) {
      dimension = this.getFirstDimensionOfDataType(['text', 'calendar_date']);
    } else if (visualizationType === 'timelineChart') {
      dimension = this.getFirstDimensionOfDataType(['calendar_date', 'text']);
    } else if (visualizationType === 'pieChart') {
      dimension = this.getFirstDimensionOfDataType(['text']);
    } else if (visualizationType === 'histogram') {
      dimension = this.getFirstDimensionOfDataType(['number']);
    }

    if (!_.isNil(dimension)) {
      if (!_.isNil(dimension.fieldName)) {
        onSetDimension(dimension.fieldName);
      }
      if (_.includes(['calendar_date', 'date', 'floating_timestamp'], dimension.dataTypeName)) {
        onSetOrderBy({ parameter: 'dimension', sort: 'asc' });
      }
    }
  };

  getFirstDimensionOfDataType = (dataTypeNames) => {
    const dimensions = getValidDimensions(this.props.metadata);

    if (_.isNil(dimensions)) {
      return null;
    }

    for (let name of dataTypeNames) {
      const firstDimension = _.find(dimensions, (dimension) => dimension.dataTypeName === name);
      if (!_.isNil(firstDimension)) {
        return firstDimension;
      }
    }

    return null;
  };

  renderVisualizationTypeFlyout = (visualizationType, isRecommended) => {
    const recommendedLabel = (
      <div className="visualization-type-recommended-label">
        <span className="visualization-type-recommended-indicator" />
        <span>{I18n.t('shared.visualizations.panes.data.fields.visualization_type.recommended')}</span>
      </div>
    );
    const recommendedInfo = (
      <p className="visualization-type-info">
        {I18n.t('shared.visualizations.panes.data.fields.visualization_type.recommended_based_on')}
      </p>
    );
    const flyoutAttributes = {
      id: `${visualizationType.type}-flyout`,
      className: classNames('visualization-type-flyout flyout flyout-hidden', {
        // Controls the visibility of recommended labels and info.
        recommended: isRecommended
      }),
      'aria-hidden': true
    };

    return (
      <div {...flyoutAttributes}>
        <section className="flyout-content">
          <h3 className="flyout-header">
            <div className="visualization-type-title">{visualizationType.title}</div>
            {recommendedLabel}
          </h3>
          {recommendedInfo}
        </section>
      </div>
    );
  };

  renderEmptyRegionAlert = () => {
    const { metadata, vifAuthoring } = this.props;
    const alertAttributes = {
      id: 'empty-region-selection-alert',
      className: 'empty-region-selection-alert alert warning'
    };

    return !hasRegions(metadata) && isRegionMap(vifAuthoring) ? (
      <div {...alertAttributes}>
        <div>
          <span className="icon-warning" />
        </div>
        <div>
          <p>{I18n.t('shared.visualizations.panes.data.fields.visualization_type.no_boundaries')}</p>
          <p
            dangerouslySetInnerHTML={{
              __html: I18n.t('shared.visualizations.panes.data.fields.visualization_type.ask_site_admin')
            }}
          />
        </div>
      </div>
    ) : null;
  };

  renderVisualizationTypeButton = (visualizationType) => {
    const areNewMapsEnabled = FeatureFlags.value('enable_new_maps');

    if (!areNewMapsEnabled && _.isEqual(visualizationType, 'map')) {
      return;
    }

    if (areNewMapsEnabled && _.includes(['featureMap', 'regionMap'], visualizationType)) {
      return;
    }

    const { metadata, vifAuthoring } = this.props;
    const recommendedVisualizationTypes = getRecommendedVisualizationTypes(
      metadata,
      getAnyDimension(vifAuthoring)
    );
    const isRecommended = _.some(recommendedVisualizationTypes, { type: visualizationType });
    const selectedVisualizationType = getSelectedVisualizationType(vifAuthoring);
    const isSelected = visualizationType === selectedVisualizationType;
    const visualizationTypeMetadata = _.find(VISUALIZATION_TYPES, { type: visualizationType });
    const flyout = this.renderVisualizationTypeFlyout(visualizationTypeMetadata, isRecommended);
    const buttonAttributes = {
      'aria-label': visualizationTypeMetadata.title,
      className: classNames({
        active: isSelected,
        recommended: isRecommended
      }),
      size: SIZES.LARGE,
      onClick: this.onClickVisualizationType(visualizationType),
      'data-flyout': `${visualizationType}-flyout`
    };

    return (
      <Button {...buttonAttributes}>
        <span className={visualizationTypeMetadata.icon} />
        {flyout}
      </Button>
    );
  };

  renderVisualizationTypeSelector = () => {
    const attributes = {
      id: 'visualization-type-selection',
      className: 'visualization-type-container',
      ref: (ref) => (this.selector = ref)
    };

    return (
      <div {...attributes}>
        <div className="visualization-type-gutter" />
        <div className="btn-group">
          {this.renderVisualizationTypeButton('barChart')}
          {this.renderVisualizationTypeButton('columnChart')}
          {this.renderVisualizationTypeButton('pieChart')}
          {this.renderVisualizationTypeButton('timelineChart')}
          {this.renderVisualizationTypeButton('histogram')}
          {this.renderVisualizationTypeButton('comboChart')}
          {this.renderVisualizationTypeButton('scatterChart')}
          {this.renderVisualizationTypeButton('featureMap')}
          {this.renderVisualizationTypeButton('regionMap')}
          {this.renderVisualizationTypeButton('map')}
          {this.renderVisualizationTypeButton('calendar')}
          {isInsituViz() && this.renderVisualizationTypeButton(SERIES_TYPE_AG_GRID_TABLE)}
        </div>
        <div className="visualization-type-gutter" />
      </div>
    );
  };

  render() {
    const { metadata } = this.props;
    const attributes = { className: 'visualization-type-selector' };

    if (hasData(metadata)) {
      return (
        <div {...attributes}>
          {this.renderVisualizationTypeSelector()}
          {this.renderEmptyRegionAlert()}
        </div>
      );
    }

    return <div {...attributes}></div>;
  }
}

VisualizationTypeSelector.defaultProps = {
  visualizationTypes: VISUALIZATION_TYPES
};

VisualizationTypeSelector.propTypes = {
  metadata: PropTypes.object,
  onChangeVisualizationType: PropTypes.func,
  onSetColorPaletteProperties: PropTypes.func,
  onSetCurrentMapLayerIndex: PropTypes.func,
  onSetDataSource: PropTypes.func,
  onSetDimension: PropTypes.func,
  onSetDefaultDisplayDate: PropTypes.func,
  onSetFilters: PropTypes.func,
  onSetMapType: PropTypes.func,
  onSetOrderBy: PropTypes.func,
  onSetShowDataTableControl: PropTypes.func,
  onSetShowMultiplePointsSymbolInLegend: PropTypes.func,
  onSetStartDateColumn: PropTypes.func,
  onSetVisualizationType: PropTypes.func,
  onSetXAxisColumn: PropTypes.func,
  onSetYAxisColumn: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetColorPaletteProperties: setColorPaletteProperties,
  onSetCurrentMapLayerIndex: setCurrentMapLayerIndex,
  onSetDataSource: setDataSource,
  onSetDefaultDisplayDate: setDefaultDisplayDate,
  onSetDimension: setDimension,
  onSetShowDataTableControl: setShowDataTableControl,
  onSetStartDateColumn: setStartDateColumn,
  onSetFilters: setFilters,
  onSetMapType: setMapType,
  onSetOrderBy: setOrderBy,
  onSetShowMultiplePointsSymbolInLegend: setShowMultiplePointsSymbolInLegend,
  onSetVisualizationType: setVisualizationType,
  onSetXAxisColumn: setXAxisColumn,
  onSetYAxisColumn: setYAxisColumn
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualizationTypeSelector);
