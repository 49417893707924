import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ExportFlannel from 'common/components/ExportFlannel';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import FilterPropType from 'common/propTypes/FilterPropType';

export class InfoPaneButtons extends PureComponent {

  render() {
    const {
      exportFormats,
      filters,
      parentView
    } = this.props;

    const whereClause = SoqlHelpers.whereClauseFilteringOwnColumn(
      {
        format: {
          type: 'visualization_interchange_format',
          version: 3
        },
        series: [
          {
            dataSource: {
              datasetUid: parentView.id,
              dimension: {},
              domain: window.location.hostname,
              type: 'socrata.soql',
              filters
            },
            type: 'table'
          }
        ]
      },
      0
    );

    const flannelProps = {
      exportFormats,
      exportFilteredData: true,
      view: parentView,
      whereClause
    };

    return (
      <div className="info-pane-buttons">
        <ExportFlannel {...flannelProps} />
      </div>
    );
  }

}

InfoPaneButtons.propTypes = {
  exportFormats: PropTypes.arrayOf(PropTypes.string),
  parentView: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(FilterPropType)
};

export function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(InfoPaneButtons);
