// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import {
  setMeasureAxisMaxValue,
  setMeasureAxisMinValue,
  setMeasureAxisScale,
  setSecondaryMeasureAxisMaxValue,
  setSecondaryMeasureAxisMinValue,
  setSecondaryMeasureAxisScale
} from '../../../actions';
import {
  getMeasureAxisMaxValue,
  getMeasureAxisMinValue,
  getMeasureAxisScale,
  getSecondaryMeasureAxisMaxValue,
  getSecondaryMeasureAxisMinValue,
  getSecondaryMeasureAxisScale,
  hasComboChartLineSeries,
  isUsingPrimaryAxis,
  isUsingPrimaryAxisOnly,
  isUsingSecondaryAxis,
  isUsingSecondaryAxisForLines,
  isUsingSecondaryAxisOnly
} from '../../../selectors/vifAuthoring';
import AxisScaleSelector from './AxisScaleSelector';
import Tabs from '../../shared/Tabs';
import I18n from 'common/i18n';

// Constants
import {
  MEASURE_AXIS_SCALE_CUSTOM,
  MEASURE_AXIS_SCALE_MIN_TO_MAX,
  MEASURE_AXIS_SCALE_ZERO_TO_MAX
} from '../../../constants';

const scope = 'shared.visualizations.panes.data.fields.combo_chart_measure_axis_options';

export class AxisScaleSelectorDual extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryAxisScale: this.getPrimaryAxisScale(props),
      secondaryAxisScale: this.getSecondaryAxisScale(props),
      tabIndex: props.selectedTabIndex || 0
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {  // eslint-disable-line camelcase
    const { primaryAxisScale, secondaryAxisScale } = this.state;
    const { vifAuthoring } = nextProps;

    if (!isUsingPrimaryAxis(vifAuthoring) ||
      (isUsingSecondaryAxisForLines(vifAuthoring) && (primaryAxisScale === MEASURE_AXIS_SCALE_MIN_TO_MAX))) {

      this.setState({
        primaryAxisScale: MEASURE_AXIS_SCALE_ZERO_TO_MAX
      });
    }

    if (!isUsingSecondaryAxis(vifAuthoring) ||
      (!isUsingSecondaryAxisForLines(vifAuthoring) && (secondaryAxisScale === MEASURE_AXIS_SCALE_MIN_TO_MAX))) {

      this.setState({
        secondaryAxisScale: MEASURE_AXIS_SCALE_ZERO_TO_MAX
      });
    }
  }

  onMaxValueTextboxChange(event) {
    const {
      onSetMeasureAxisMaxValue,
      onSetMeasureAxisScale,
      onSetSecondaryMeasureAxisMaxValue,
      onSetSecondaryMeasureAxisScale
    } = this.props;

    if (this.isPrimaryAxis()) {
      onSetMeasureAxisMaxValue(event.target.value);
      onSetMeasureAxisScale();
    } else {
      onSetSecondaryMeasureAxisMaxValue(event.target.value);
      onSetSecondaryMeasureAxisScale();
    }
  }

  onMinValueTextboxChange(event) {
    const {
      onSetMeasureAxisMinValue,
      onSetMeasureAxisScale,
      onSetSecondaryMeasureAxisMinValue,
      onSetSecondaryMeasureAxisScale
    } = this.props;

    if (this.isPrimaryAxis()) {
      onSetMeasureAxisMinValue(event.target.value);
      onSetMeasureAxisScale();
    } else {
      onSetSecondaryMeasureAxisMinValue(event.target.value);
      onSetSecondaryMeasureAxisScale();
    }
  }

  onRadioButtonChange(event) {
    const {
      onSetMeasureAxisMaxValue,
      onSetMeasureAxisMinValue,
      onSetMeasureAxisScale,
      onSetSecondaryMeasureAxisMaxValue,
      onSetSecondaryMeasureAxisMinValue,
      onSetSecondaryMeasureAxisScale
    } = this.props;

    const axisScale = event.target.value;

    if (this.isPrimaryAxis()) {
      this.setState({
        primaryAxisScale: axisScale
      });

      switch (axisScale) {
        case MEASURE_AXIS_SCALE_ZERO_TO_MAX:
          onSetMeasureAxisScale();
          onSetMeasureAxisMaxValue();
          onSetMeasureAxisMinValue();
          break;
        case MEASURE_AXIS_SCALE_MIN_TO_MAX:
          onSetMeasureAxisScale(MEASURE_AXIS_SCALE_MIN_TO_MAX);
          onSetMeasureAxisMaxValue();
          onSetMeasureAxisMinValue();
          break;
      }
    } else {
      this.setState({
        secondaryAxisScale: axisScale
      });

      switch (axisScale) {
        case MEASURE_AXIS_SCALE_ZERO_TO_MAX:
          onSetSecondaryMeasureAxisScale();
          onSetSecondaryMeasureAxisMaxValue();
          onSetSecondaryMeasureAxisMinValue();
          break;
        case MEASURE_AXIS_SCALE_MIN_TO_MAX:
          onSetSecondaryMeasureAxisScale(MEASURE_AXIS_SCALE_MIN_TO_MAX);
          onSetSecondaryMeasureAxisMaxValue();
          onSetSecondaryMeasureAxisMinValue();
          break;
      }
    }
  }

  isPrimaryAxis() {
    return (this.state.tabIndex === AxisScaleSelectorDual.primaryAxisTabIndex);
  }

  getPrimaryAxisScale(props) {
    const { vifAuthoring } = props;
    const maxValue = getMeasureAxisMaxValue(vifAuthoring);
    const minValue = getMeasureAxisMinValue(vifAuthoring);

    if (!_.isNil(maxValue) || !_.isNil(minValue)) {
      return MEASURE_AXIS_SCALE_CUSTOM;
    } else if (getMeasureAxisScale(vifAuthoring) === MEASURE_AXIS_SCALE_MIN_TO_MAX) {
      return MEASURE_AXIS_SCALE_MIN_TO_MAX;
    } else {
      return MEASURE_AXIS_SCALE_ZERO_TO_MAX;
    }
  }

  getSecondaryAxisScale(props) {
    const { vifAuthoring } = props;
    const maxValue = getSecondaryMeasureAxisMaxValue(vifAuthoring);
    const minValue = getSecondaryMeasureAxisMinValue(vifAuthoring);

    if (!_.isNil(maxValue) || !_.isNil(minValue)) {
      return MEASURE_AXIS_SCALE_CUSTOM;
    } else if (getSecondaryMeasureAxisScale(vifAuthoring) === MEASURE_AXIS_SCALE_MIN_TO_MAX) {
      return MEASURE_AXIS_SCALE_MIN_TO_MAX;
    } else {
      return MEASURE_AXIS_SCALE_ZERO_TO_MAX;
    }
  }

  renderMeasureAxisOptions() {
    const { vifAuthoring } = this.props;
    const {
      primaryAxisScale,
      secondaryAxisScale
    } = this.state;

    const axisScale = this.isPrimaryAxis() ? primaryAxisScale : secondaryAxisScale;
    const maxLimit = this.isPrimaryAxis() ? getMeasureAxisMaxValue(vifAuthoring) : getSecondaryMeasureAxisMaxValue(vifAuthoring);
    const minLimit = this.isPrimaryAxis() ? getMeasureAxisMinValue(vifAuthoring) : getSecondaryMeasureAxisMinValue(vifAuthoring);
    const disabled =
      (this.isPrimaryAxis() && isUsingSecondaryAxisOnly(vifAuthoring)) ||
      (!this.isPrimaryAxis() && isUsingPrimaryAxisOnly(vifAuthoring));

    const disableScaleMinToMaxValue =
      !hasComboChartLineSeries(vifAuthoring) ||
      (this.isPrimaryAxis() && isUsingSecondaryAxisForLines(vifAuthoring)) ||
      (!this.isPrimaryAxis() && !isUsingSecondaryAxisForLines(vifAuthoring));

    const selectorAttributes = {
      axisScale,
      disabled,
      disableScaleMinToMaxValue,
      maxLimit: maxLimit || '',
      minLimit: minLimit || '',
      onMaxValueTextboxChange: (event) => this.onMaxValueTextboxChange(event),
      onMinValueTextboxChange: (event) => this.onMinValueTextboxChange(event),
      onRadioButtonChange: (event) => this.onRadioButtonChange(event)
    };

    return (
      <AxisScaleSelector {...selectorAttributes} />
    );
  }

  renderTab({ title, selected, tabIndex }) {
    const linkAttributes = {
      className: selected ? 'selected' : null,
      onClick: () => this.setState({ tabIndex })
    };

    return (
      <li>
        <a {...linkAttributes}>{title}</a>
      </li>
    );
  }

  renderTabs() {
    const { tabIndex } = this.state;
    const tabs = [
      {
        onClickTab: (tabIndex) => this.setState({ tabIndex }),
        selected: (tabIndex === AxisScaleSelectorDual.primaryAxisTabIndex),
        tabIndex: AxisScaleSelectorDual.primaryAxisTabIndex,
        title: I18n.translate('primary_axis', { scope })
      },
      {
        onClickTab: (tabIndex) => this.setState({ tabIndex }),
        selected: (tabIndex === AxisScaleSelectorDual.secondaryAxisTabIndex),
        tabIndex: AxisScaleSelectorDual.secondaryAxisTabIndex,
        title: I18n.translate('secondary_axis', { scope })
      }
    ];

    return (
      <Tabs tabs={tabs} />
    );
  }

  render() {
    return (
      <div className="measure-axis-scale-dual-container">
        {this.renderTabs()}
        {this.renderMeasureAxisOptions()}
      </div>
    );
  }
}

AxisScaleSelectorDual.primaryAxisTabIndex = 0;
AxisScaleSelectorDual.secondaryAxisTabIndex = 1;

AxisScaleSelectorDual.propTypes = {
  metadata: PropTypes.object,
  onSetMeasureAxisMaxValue: PropTypes.func,
  onSetMeasureAxisMinValue: PropTypes.func,
  onSetMeasureAxisScale: PropTypes.func,
  onSetSecondaryMeasureAxisMaxValue: PropTypes.func,
  onSetSecondaryMeasureAxisMinValue: PropTypes.func,
  onSetSecondaryMeasureAxisScale: PropTypes.func,
  selectedTabIndex: PropTypes.number,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetMeasureAxisMaxValue: setMeasureAxisMaxValue,
  onSetMeasureAxisMinValue: setMeasureAxisMinValue,
  onSetMeasureAxisScale: setMeasureAxisScale,
  onSetSecondaryMeasureAxisMaxValue: setSecondaryMeasureAxisMaxValue,
  onSetSecondaryMeasureAxisMinValue: setSecondaryMeasureAxisMinValue,
  onSetSecondaryMeasureAxisScale: setSecondaryMeasureAxisScale
};

const mapStateToProps = (state) => _.pick(state, ['vifAuthoring']);

export default connect(mapStateToProps, mapDispatchToProps)(AxisScaleSelectorDual);
