// Vendor Imports
import { find, filter, get, isEmpty, isString } from 'lodash';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

// Project Imports
import { getCurrentDomain } from 'common/currentDomain';
import { setFilters } from '../actions';
import { getCurrentMetadata, getFilterableColumns, getValidComputedColumns } from '../selectors/metadata';
import { getCurrentVif, getShowNullsAsFalse } from '../selectors/vifAuthoring';
import {
  getFilterConstraints,
  getSanitizeFilters,
  getSanitizeFilterParameterConfig
} from 'common/visualizations/helpers/VifHelpers';
import FilterBar from 'common/components/FilterBar';
import { VerticalFilterBar as NewVerticalFilterBar } from 'common/components/FilterBar/VerticalFilterBar';

const mapStateToProps = (state) => {
  const { vifAuthoring } = state;
  const metadata = getCurrentMetadata(state.metadataCollection, state.vifAuthoring);
  const vif = getCurrentVif(vifAuthoring);
  const filterConstraints = getFilterConstraints(vif);
  const showNullsAsFalse = getShowNullsAsFalse(vifAuthoring);
  const columns = getFilterableColumns(metadata);
  const computedColumns = getValidComputedColumns(metadata);

  const filters = filter(vifAuthoring.authoring.filters, (fil) => {
    const columnName = fil.columns[0].fieldName;
    return (
      isString(columnName) &&
      (find(columns, ['fieldName', columnName]) || find(computedColumns, ['fieldName', columnName]))
    );
  });

  const datasetUid = isEmpty(vif)
    ? vifAuthoring.vifs.initialVif.series[0].dataSource.datasetUid
    : vif.series[0].dataSource.datasetUid;
  const filterColumns = { [datasetUid]: columns };
  const renderAddFilterButton = { shouldRenderAddFilter: true };
  let dataSource = {
    datasetUid: metadata.datasetUid,
    // Warning: EN-28544 means we need to always hit the *primary* cname, not an alias.
    // We don't always know what the primary cname is, especially if we've been embedded into a 3rd-party site
    // There's no reliable way to get this today, best fix is to address the issue at the API level.
    domain:
      // Conspicuously missing: viewMetadata.domainCName. We don't have the view metadata yet.
      // Wouldn't matter if we could simply use window.location.hostname, see JIRA ticket above.
      get(vif, 'series[0].dataSource.domain', getCurrentDomain())
  };
  dataSource = [dataSource];

  return {
    columns: filterColumns,
    computedColumns,
    constraints: filterConstraints,
    filterParameterConfigurations: getSanitizeFilterParameterConfig(filters, vif),
    isReadOnly: false,
    useForgeStyle: false,
    showNullsAsFalse,
    dataSource,
    ...renderAddFilterButton,
    shouldMaintainColumnName: false,
    showRemoveButtonInFilterEditor: true
  };
};

const mapDispatchToProps = (dispatch) => {

  return bindActionCreators({ onUpdate: setFilters, }, dispatch);

};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { onUpdate: onUpdateOwnProps } = ownProps;
  const { onUpdate: onUpdateDispatchProps } = dispatchProps;

  const mergedOnUpdate = (...args) => {
    onUpdateOwnProps && onUpdateOwnProps(...args);
    onUpdateDispatchProps && onUpdateDispatchProps(...args);
  };

  return { ...ownProps, ...stateProps, ...dispatchProps, onUpdate: mergedOnUpdate };
};

const getFilterBar = (isVertical) => isVertical ? NewVerticalFilterBar : FilterBar;

export default {
  Vertical: compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(getFilterBar(true)),
  Horizontal: compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(getFilterBar(false))
};
