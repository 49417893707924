import React from 'react';
import _ from 'lodash';
import ForgeTab from './ForgeTab';
import CustomizationTab from './CustomizationTab';
import { ForgeList, ForgeMiniDrawer } from '@tylertech/forge-react';
import FeatureFlags from 'common/feature_flags';

interface ICustomizationTab {
  id: string;
  title: string;
  icon: string;
}

interface ICustomizationTabsProps {
  tabs: ICustomizationTab[];
  selection: string;
  onTabNavigation: (tabId: string) => void;
}

export const CustomizationTabs = (props: ICustomizationTabsProps) => {
  const enableForgeTabs = FeatureFlags.valueOrDefault('enable_forge_authoring_tabs', false);

  const tabAttributes = (tab: ICustomizationTab) => {
    return {
      id: tab.id,
      key: tab.id,
      title: tab.title,
      icon: tab.icon,
      selected: props.selection === tab.id,
      onTabNavigation: props.onTabNavigation
    };
  };

  const renderTab = (tab: ICustomizationTab) => {
    const TabComponent = enableForgeTabs ? ForgeTab : CustomizationTab;
    return <TabComponent {...tabAttributes(tab)} />;
  };

  if (enableForgeTabs)
    return (
      <ForgeMiniDrawer direction="left" className="content-sidebar-tabs">
        <ForgeList>{_.map(props.tabs, renderTab)}</ForgeList>
      </ForgeMiniDrawer>
    );

  return (
    <ul role="tablist" className="nav-tabs">
      {_.map(props.tabs, renderTab)}
    </ul>
  );
};

export default CustomizationTabs;
